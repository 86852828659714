import React, { useState } from 'react';
import { Input, Spin } from 'antd';
import { AddUpdateQuestionApi } from '../../../api/request/organizer.js';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import CellDropdown from '../components/CellDropdown.jsx';

export default function MyCheckBox({ question }) {

    const [value, setValue] = useState(question?.field_label);
    const [options, setOptions] = useState((question?.options)?.map((item, index) => { return { id: index + 1, value: item } }) || []);
    const [isCompleted, setIsCompleted] = useState(value?.length ? true : false);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        setValue(event?.target?.value);
        setIsCompleted(false);
    }

    const addNewOption = () => {
        const newId = (options.length + 1).toString(); // Increment ID
        setOptions([...options, { id: newId, option: '' }]);
        setIsCompleted(false);
    };

    const removeOption = (id) => {
        setOptions(options?.filter(option => option.id !== id));
        setIsCompleted(false);
        handleSubmit(options?.filter(option => option.id !== id));
    };

    const updateOptionValue = (id, newValue) => {
        setIsCompleted(false);
        let updatedArray = options.map(option =>
            option.id === id ? { ...option, value: newValue } : option
        );
        setOptions(updatedArray);
    };

    const handleSubmit = async (item) => {
        if (!value) { return; }
        try {
            setIsLoading(true);
            let questionOption = Array.isArray(item) ? item : options;
            let params = {
                question_id: question?.id,
                section_id: question?.organizer_section_id,
                field_label: value,
                options: questionOption?.map(item => item.value)?.filter((item) => item !== null)
            };
            const { data } = await AddUpdateQuestionApi(params);
            if (data?.status) {
                setIsCompleted(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className='my-2'>
            <div className='flex items-center gap-4'>
                <img src='/assets/icons/double-ellipsis-vertical.svg' className='cursor-grab' />
                <Input
                    className='py-3 ps-4'
                    size='large'
                    placeholder='Checkbox'
                    onChange={onChange}
                    value={value}
                    onBlur={handleSubmit}
                />
                <CellDropdown question={question} setIsLoading={setIsLoading} />
                <div className='h-[22px] w-[22px]'>
                    {isLoading ? (
                        <Spin indicator={<LoadingOutlined spin />} className='size-[22px]' />
                    ) : (
                        <div className='size-[22px]'>{isCompleted ? <img className='size-6' src='/assets/icons/check-circle.svg' alt='icon' /> : <img className='size-[22px]' src='/assets/icons/x-circle.svg' alt='icon' />}</div>
                    )}
                </div>
            </div>

            <div className='md:ms-8'>
                <div className='my-3'>
                    {options?.map((option, index) => (
                        <div key={index} className='section-item flex items-center font-semibold rounded-md duration-500'>
                            <Input
                                className='font-medium text-secondaryLight2'
                                size='large'
                                placeholder='Option'
                                value={option?.value}
                                onChange={(e) => updateOptionValue(option?.id, e.target.value)}
                                onBlur={handleSubmit}
                            />
                            <img src='/assets/icons/trash-red.svg' className='cursor-pointer size-5' onClick={() => removeOption(option.id)} />
                        </div>
                    ))}
                </div>
                <button className='cursor-pointer flex gap-3 mt-5' onClick={addNewOption}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <p className='font-medium'>Add New Option</p>
                </button>
            </div>
        </div>
    )
}