import ChangePassword from '../pages/auth/ChangePassword.jsx';
import Forgot from '../pages/auth/Forgot.jsx';
import Login from '../pages/auth/Login.jsx';
import Otp from '../pages/auth/Otp.jsx';
import Dashboard from '../pages/dashboard/Dashboard.jsx';
import Account from '../pages/account/Account.jsx';
import Organizer from '../pages/templates/organizer/Organizer.jsx';
import OrganizerInit from '../pages/templates/organizer/OrganizerInit.jsx';
import CellsAndForms from '../pages/settings/cellsAndForm/CellsAndForms.jsx';
import ReviewDoc from '../pages/reviewDoc/ReviewDoc.jsx';
import ViewReviewDoc from '../pages/reviewDoc/components/ViewReviewDoc.jsx';
import PrepareTax from '../pages/prepareTax/PrepareTax.jsx';
import ViewPrepareTax from '../pages/prepareTax/components/ViewPrepareTax.jsx';
import ReviewTax from '../pages/reviewTax/ReviewTax.jsx';
import FollowUp from '../pages/followUp/FollowUp.jsx';
import ReturnToGov from '../pages/returnToGov/ReturnToGov.jsx';
import Team from '../pages/team/Team.jsx';
import Clients from '../pages/clients/Clients.jsx';
import Client from '../pages/clients/components/Client.jsx';
import InvoiceTemplate from '../pages/templates/Invoice/InvoiceTemplate.jsx';
import Communication from '../pages/communication/Communication.jsx';
import BusinessTask from '../pages/businessTask/BusinessTask.jsx';
import Tags from '../pages/settings/tags/Tags.jsx';
import Provinces from '../pages/settings/provinces/Provinces.jsx';
import Organizers from '../pages/organizers/Organizers.jsx';
import EmployeeDashboard from '../pages/employeeDashboard/EmployeeDashboard.jsx';

export const initialRoutes = () => {
    const routes = [
        // ============ Auth ===============
        { path: "/login", type: "auth", component: Login, title: 'Login' },
        { path: "/otp", type: "auth", component: Otp, title: 'Otp' },
        { path: "/forgot_password", type: "auth", component: Forgot, title: 'ForgotPassword' },
        { path: "/change_password/:token", type: "auth", component: ChangePassword, title: 'ChangePassword' },


        // ============ Privet ==============
        { path: "/dashboard", type: "private", component: Dashboard, title: "Dashboard" },

        { path: "/employee_dashboard", type: "private", component: EmployeeDashboard, title: "Employee Dashboard" },

        { path: "/organizer_template/:organizerTypeId", type: "private", component: Organizer, title: "Organizer Template" },
        { path: "/organizer_template", type: "private", component: OrganizerInit, title: "Organizer Template" },

        { path: "/account", type: "private", component: Account, title: "Account" },

        { path: "/review_doc", type: "private", component: ReviewDoc, title: "Organizers" },
        { path: "/review_doc/:id", type: "private", component: ViewReviewDoc, title: "Organizers" },

        { path: "/prepare_tax", type: "private", component: PrepareTax, title: "Organizers" },
        { path: "/prepare_tax/:id", type: "private", component: ViewPrepareTax, title: "Organizers" },

        { path: "/review_tax", type: "private", component: ReviewTax, title: "Organizers" },

        { path: "/follow_up", type: "private", component: FollowUp, title: "Organizers" },

        { path: "/return_to_gov", type: "private", component: ReturnToGov, title: "Organizers" },

        { path: "/team", type: "private", component: Team, title: "Team" },

        { path: "/communication", type: "private", component: Communication, title: "Communication" },

        { path: "/clients", type: "private", component: Clients, title: "Clients" },
        { path: "/client/:id", type: "private", component: Client, title: "Client" },

        { path: "/task", type: "private", component: BusinessTask, title: "Business Task" },

        { path: "/organizers", type: "private", component: Organizers, title: "Organizers" },

        { path: "/cells_and_forms", type: "private", component: CellsAndForms, title: "Cells & Forms" },
        { path: "/invoice_template", type: "private", component: InvoiceTemplate, title: "Invoice Templates" },
        { path: "/tags", type: "private", component: Tags, title: "Tags" },
        { path: "/provinces", type: "private", component: Provinces, title: "Provinces" },
    ]
    return routes;
}