import { Avatar } from 'antd';
import React from 'react';

export default function Information({ client }) {

  return (
    <div>
      <div className='grid md:grid-cols-3 gap-5 m-4'>
        <div className='md:col-span-2 border-2 rounded-lg'>
          <div className='p-4'>
            <p className='text-lg font-semibold'>Account details</p>
          </div>

          <div className='border-y-2 mx-4 mb-4 flex items-center gap-5 md:p-4'>
            <Avatar src='/assets/images/user.png' size={60} />
            <div>
              <p className='text-lg font-semibold capitalize'>{client?.name}</p>
              <p className='text-secondaryLight2 capitalize'>{client?.account_type}</p>
            </div>
          </div>

          <div className='flex items-center gap-12 mx-4 mb-4 md:px-4'>
            <div>
              <p className='font-medium'>Tags</p>
              <p className='font-medium my-2'>Team Members</p>
              <p className='font-medium my-2'>SIN/BIN</p>
              <p className='font-medium'>Province</p>
            </div>
            <div>
              <p>Tax Return</p>
              <p className='my-2'>Anita Cruz</p>
              <p className='my-2'>70356846521569 </p>
              <p>Ontario</p>
            </div>
          </div>
        </div>

        <div className='border-2 rounded-lg'>
          <div className='p-4'>
            <p className='text-lg font-semibold'>Client Download Mobile App</p>
          </div>

          <div className='border-t-2 mx-4 mb-4 pt-4 flex items-center gap-5 flex-wrap'>
            <button className='h-10'><img src="/assets/icons/playstore-btn.svg" alt="btn" className='object-contain' /></button>
            <button className='h-10'><img src="/assets/icons/appstore-btn.svg" alt="btn" className='object-contain' /></button>
          </div>
        </div>
      </div>
    </div>
  )
}