import Dashboard from "../../../../assets/Dashboard.jsx";
import Organizer from "../../../../assets/Organizer.jsx";
import ReviewDoc from "../../../../assets/ReviewDoc.jsx";
import PrepareTax from "../../../../assets/PrepareTax.jsx";
import ReviewTax from "../../../../assets/ReviewTax.jsx";
import FollowUp from "../../../../assets/FollowUp.jsx";
import ReturnToGov from "../../../../assets/ReturnToGov.jsx";

const rolesData = {
    'Dashboard': {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/employee_dashboard',
        activeUrl: ['/employee_dashboard']
    },
    'Organizer Manage': {
        title: 'Organizers',
        icon: <Organizer />,
        url: '/organizers',
        activeUrl: ['/organizers']
    },
    'Review Doc': {
        title: 'Review Doc',
        icon: <ReviewDoc />,
        url: '/review_doc',
        activeUrl: ['/review_doc']
    },
    'Prepare Tax': {
        title: 'Prepare Tax',
        icon: <PrepareTax />,
        url: '/prepare_tax',
        activeUrl: ['/prepare_tax']
    },
    'Review Tax': {
        title: 'Review Tax',
        icon: <ReviewTax />,
        url: '/review_tax',
        activeUrl: ['/review_tax']
    },
    'Follow Up': {
        title: 'Follow Up',
        icon: <FollowUp />,
        url: '/follow_up',
        activeUrl: ['/follow_up']
    },
    'Return to gov': {
        title: 'Return to Gov',
        icon: <ReturnToGov />,
        url: '/return_to_gov',
        activeUrl: ['/return_to_gov']
    }
}

export default rolesData;