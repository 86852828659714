import React from 'react'

export default function Clients({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.90673 11.8043L2.35297 13.358C1.92401 13.787 1.92401 14.4826 2.35297 14.9117C2.78212 15.3408 3.47772 15.3408 3.90668 14.9117L5.46039 13.358C5.88959 12.9289 5.88959 12.2333 5.46039 11.8043C5.03144 11.3751 4.33593 11.3751 3.90673 11.8043ZM9.08569 16.9834L7.53197 18.5371C7.10302 18.966 7.10302 19.6616 7.53197 20.0906C7.96093 20.5198 8.65654 20.5198 9.08569 20.0906L10.6394 18.5371C11.0684 18.1079 11.0684 17.4123 10.6394 16.9834C10.2103 16.5542 9.51489 16.5542 9.08569 16.9834ZM12.711 21.6443L13.2289 21.1265C13.6581 20.6973 13.6581 20.0017 13.2289 19.5728C12.7999 19.1438 12.1043 19.1438 11.6751 19.5728L11.1573 20.0906C10.7283 20.5198 10.7283 21.2154 11.1573 21.6443C11.5862 22.0735 12.2821 22.0735 12.711 21.6443ZM7.53197 13.358L4.94257 15.9475C4.51337 16.3766 4.51337 17.0722 4.94257 17.5012C5.37152 17.9302 6.06713 17.9302 6.49609 17.5012L9.08569 14.9118C9.51469 14.4826 9.51469 13.787 9.08569 13.3581C8.65654 12.9289 7.96117 12.9289 7.53197 13.3581V13.358Z" fill={`${active ? primaryColor : 'black'}`} />
            <path d="M9.346 2.2207L7.46514 0.320068C7.17906 0.0339355 6.71538 0.0339355 6.4293 0.320068L0.2146 6.53477C-0.0715333 6.8209 -0.0715333 7.28453 0.2146 7.57066L1.48428 8.86001C1.88621 9.26208 2.18908 9.75215 2.36895 10.2915L2.61343 11.0251L2.87246 10.766C3.87193 9.76675 5.49829 9.76675 6.49776 10.766C6.81441 11.0828 7.01924 11.4652 7.13521 11.8681C8.10035 11.3686 9.31475 11.5114 10.1231 12.3197C10.9314 13.1281 11.0742 14.3425 10.5747 15.3078C10.9775 15.4238 11.36 15.6282 11.6768 15.945C12.1841 16.4524 12.4312 17.1215 12.4233 17.788C13.0899 17.7803 13.7588 18.0273 14.2662 18.5347L15.3021 19.5703C15.7311 19.9995 16.4267 19.9995 16.8556 19.5703C17.2848 19.1414 17.2848 18.4458 16.8556 18.0166L15.0431 16.2041C14.7569 15.918 14.7569 15.4543 15.0431 15.1682C15.3292 14.8823 15.7929 14.8823 16.0788 15.1682L17.8915 16.9809C18.3205 17.4099 19.0163 17.4099 19.4452 16.9809C19.8742 16.552 19.8742 15.8562 19.4452 15.4272L17.6325 13.6145C17.3466 13.3286 17.3466 12.8649 17.6325 12.5788C17.9186 12.2927 18.3825 12.2927 18.6684 12.5788L20.4811 14.3913C20.9101 14.8203 21.6057 14.8203 22.0348 14.3913C22.4638 13.9624 22.4638 13.2668 22.0348 12.8378L17.5802 8.38321C16.159 9.32291 14.222 9.16807 12.9715 7.91758C12.6858 7.63189 12.2215 7.63189 11.9358 7.91758C10.508 9.34542 8.18462 9.34542 6.75679 7.91758C5.9 7.06099 5.9 5.6669 6.75679 4.81031L9.346 2.2207Z" fill={`${active ? primaryColor : 'black'}`} />
            <path d="M18.5701 0.247803C18.2839 -0.0383301 17.8203 -0.0383301 17.5342 0.247803L15.985 1.79688C15.7131 2.06885 15.3443 2.22165 14.9596 2.22168H12.3271C11.7444 2.2217 11.1856 2.4532 10.7736 2.86524L7.79182 5.84698C7.50168 6.13711 7.50168 6.59278 7.79182 6.88287C8.65144 7.74254 10.0394 7.74254 10.8991 6.88287C11.6739 6.10811 12.8879 6.03394 13.7468 6.66031C14.9018 7.50294 16.4422 7.55445 17.4533 6.54336L17.6318 6.36485L22.671 11.4041C22.7409 11.146 22.7422 10.8729 22.6739 10.6194C22.5555 10.1801 22.7273 9.55582 23.0491 9.23409L24.7848 7.49839C25.0709 7.21226 25.0709 6.74859 24.7848 6.4627L18.5701 0.247803Z" fill={`${active ? primaryColor : 'black'}`} />
        </svg>
    )
}
