import React from 'react';

export default function PrepareTax({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.93916 21.6631V1.6588H18.9212L18.9218 21.6631C18.9211 22.2583 19.0918 22.8412 19.4135 23.3421H3.61817C2.69236 23.3421 1.93916 22.5889 1.93916 21.6631ZM20.3609 17.9213L20.3604 0.219646H0.5V21.6631C0.5 23.3824 1.89881 24.7812 3.61817 24.7812H22.04C22.04 24.7812 20.3609 23.3654 20.3609 21.7107V17.9689V17.9213Z" fill={`${active ? primaryColor : 'black'}`}/>
        <path d="M5.20091 7.76953H6.39828V5.04137H7.48005V4.12367H4.11914V5.04137H5.20091V7.76953Z" fill={`${active ? primaryColor : 'black'}`}/>
        <path d="M10.0371 5.25734L10.4309 6.27665H9.64322L10.0371 5.25734ZM10.7554 7.12575L11.0034 7.76953H12.2378L10.644 4.12362H9.45119L7.85742 7.76953H9.07077L9.31879 7.12575H10.7554Z" fill={`${active ? primaryColor : 'black'}`}/>
        <path d="M12.707 7.76953H14.0666L14.7023 6.76217L15.3541 7.76953H16.7192L15.3748 5.88318L16.6398 4.12367H15.3592L14.7344 5.07159L14.0934 4.12367H12.754L14.0248 5.93091L12.707 7.76953Z" fill={`${active ? primaryColor : 'black'}`}/>
        <path d="M16.7402 19.1068C16.7402 18.2596 15.9252 18.097 15.33 17.9783C14.9769 17.9078 14.698 17.8522 14.698 17.6715C14.698 17.5546 14.7855 17.4153 15.2021 17.4153C15.5228 17.4153 15.8648 17.5045 16.2189 17.6806L16.5754 16.8219C16.2939 16.6682 15.9339 16.5671 15.5323 16.5291L15.5106 16.5271V15.9646H14.8145V16.5366L14.7939 16.5395C13.994 16.6517 13.5164 17.0985 13.5164 17.7347C13.5164 18.5866 14.3249 18.7549 14.9152 18.8778C15.2746 18.9526 15.5586 19.0117 15.5586 19.2017C15.5586 19.3549 15.3984 19.4263 15.0544 19.4263C14.6598 19.4263 14.1997 19.3012 13.8466 19.0988L13.4648 19.9626C13.7822 20.1518 14.2879 20.2874 14.792 20.3176L14.8145 20.319V20.877H15.5106V20.2951L15.5305 20.2916C16.2767 20.1616 16.7402 19.7076 16.7402 19.1068Z" fill={`${active ? primaryColor : 'black'}`}/>
        <path d="M4.03516 11.4632H16.8277V10.0241H4.03516V11.4632ZM4.03516 14.5334H16.8277V13.0943H4.03516V14.5334ZM4.03516 17.6036H11.1989V16.1645H4.03516V17.6036ZM4.03516 20.6738H11.1989V19.2347H4.03516V20.6738Z" fill={`${active ? primaryColor : 'black'}`}/>
        </svg>        
    )
}