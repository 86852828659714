import React, { useState } from 'react';
import BarChart from '../../components/charts/BarChart.jsx';
import { Calendar } from 'antd';

export default function EmployeeDashboard() {

  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim()
  const secondary = (getComputedStyle(document.documentElement).getPropertyValue('--secondaryLight1'))?.trim();
  const dashboardCards = [
    { title: 'Active Task', total: '02', icon: '/assets/icons/active-task.svg', bgColor: '#DDF6E8' },
    { title: 'Overdue Task', total: '03', icon: '/assets/icons/overdue-task.svg', bgColor: '#FBEAEA' },
    { title: 'Total Task', total: '05', icon: '/assets/icons/total-task.svg', bgColor: '#E9E7FD' },
  ];
  const [taskData, setTaskData] = useState({ categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], series: [{ name: 'Completed', data: [10, 18, 21, 33, 18, 15, 22] }, { name: 'Pending', data: [3, 6, 11, 13, 9, 5, 8], prefix: '', postfix: '' }] });

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  return (
    <div>
      <div className='grid sm:grid-cols-3 gap-5'>
        {dashboardCards.map((item, index) =>
          <div key={index} className='border-2 rounded-xl p-4'>
            <div className='flex justify-between items-center'>
              <div>
                <p className='font-medium'>{item.title}</p>
                <p className='font-semibold text-2xl mt-2'>{item.total}</p>
              </div>
              <div className='rounded-xl size-14 flex items-center justify-center' style={{ backgroundColor: item.bgColor }}>
                <img src={item.icon} alt='icon' />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='grid sm:grid-cols-4 gap-5 mt-5'>
        <div className='sm:col-span-3 border-2 rounded-xl'>
          <div className='flex justify-end mt-5 mx-5'>
            <div className='flex items-center gap-6'>
              <div className='flex items-center gap-3'>
                <div className='size-4 bg-secondaryLight1 rounded-full'></div>
                <p className='text-secondaryLight1'>Completed</p>
              </div>
              <div className='flex items-center gap-3'>
                <div className='size-4 bg-primary rounded-full'></div>
                <p className='text-secondaryLight1'>Pending</p>
              </div>
            </div>
          </div>
          <BarChart data={taskData} colors={[secondary, primaryColor]} />
        </div>
        <div className='flex flex-col justify-between gap-5'>
          <div className='flex justify-center items-center rounded-xl bg-[#FFF0E6]'>
            <div className='text-center my-7'>
              <p className='text-2xl font-bold'>03</p>
              <p className='font-semibold'>Client Delay</p>
            </div>
          </div>
          <div className='flex justify-center items-center rounded-xl bg-[#ECEAFE]'>
            <div className='text-center my-7'>
              <p className='text-2xl font-bold'>02</p>
              <p className='font-semibold'>No Response</p>
            </div>
          </div>
          <div className='flex justify-center items-center rounded-xl bg-[#E5F7FF]'>
            <div className='text-center my-7'>
              <p className='text-2xl font-bold'>06</p>
              <p className='font-semibold'>Document Not Ready</p>
            </div>
          </div>
        </div>
      </div>

      <div className='border-2 rounded-xl my-5 p-2'>
        <Calendar onPanelChange={onPanelChange} />
      </div>
    </div>
  )
}