import ProtectedRoute from "./routes/ProtectedRoute";
import './App.scss';
import { initialRoutes } from "./routes/routes.js";

const generateRoutes = (routes) => {
  return routes.map(({ type, component: Component, children, ...rest }) => {
    return {
      element: (
        <ProtectedRoute type={type} {...rest} >
          <Component />
        </ProtectedRoute>
      ),
      children: children?.length ? generateRoutes(children) : null,
      ...rest,
    };
  });
};

const AppWrapper = generateRoutes(initialRoutes());
export default AppWrapper;