import React, { useEffect, useState } from 'react'
import SkeletonTable from '../../../../components/table/SkeletonTable'
import columns from '../../../../columns/settings/forms.js';
import ModalComponent from '../../../../components/modals/ModalComponent';
import { Form, Input, Select } from 'antd';
import LoadableButton from '../../../../components/buttons/LoadableButton';
import { AddUpdateFormInformationApi, FormInformationListApi, DeleteFormInformationApi } from '../../../../api/request/settings/cellsAndForm.js';
import toast from 'react-hot-toast';
import Add from '../../../../assets/Add';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import Edit from '../../../../assets/Edit';
import Delete from '../../../../assets/Delete';
import moment from 'moment';

export default function Forms() {

  const [form] = Form.useForm();
  const initialValues = { name: '' };
  const [isAddEditFormModalOpen, setIsAddEditFormModalOpen] = useState(false);
  const [isAddEditFormLoading, setIsAddEditFormLoading] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isFormDelete, setIsFormDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formId, setFormId] = useState('');
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [formInformationList, setFormInformationList] = useState([]);

  const getFormInformationList = async () => {
    try {
      setIsLoading(true);
      const { data } = await FormInformationListApi();
      if (data?.status) {
        setFormInformationList(data?.data?.map((item) => {
          return {
            ...item,
            status: <div className={`${item?.status ? 'text-green-500' : 'text-red-500'} font-semibold`}>{item?.status ? 'Active' : 'Inactive'}</div>,
            created_at: item?.created_at ? (moment(item?.created_at).format('LL')) : '',
            action: <div className='flex items-center gap-3'>
              <button onClick={() => handleEditModal(item)}><Edit /></button>
              <button onClick={() => { setIsFormDelete(true); setFormId(item?.id) }}><Delete /></button>
            </div>
          };
        }));
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getFormInformationList();
  }, []);

  const handleEditModal = (cell) => {
    setIsAddEditFormModalOpen(true);
    setIsEditForm(true);
    setFormId(cell.id);
    form.setFieldsValue({ name: cell?.name });
  };

  const handleAddEditForm = async (values) => {
    try {
      setIsAddEditFormLoading(true);
      const params = isEditForm ? { ...values, form_info_id: formId } : values;
      const { data } = await AddUpdateFormInformationApi(params);
      if (data?.status) {
        setIsAddEditFormModalOpen(false);
        getFormInformationList();
        toast.success(data?.message);
      }
      setIsAddEditFormLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsAddEditFormLoading(false);
    }
  }

  const handleDeleteCell = async () => {
    setIsLoadingDelete(true);
    try {
      const { data } = await DeleteFormInformationApi({ form_info_id: formId });
      if (data?.status) {
        setIsFormDelete(false);
        getFormInformationList();
        toast.success(data?.message);
      }
      setIsLoadingDelete(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoadingDelete(false);
    }
  }

  return (
    <div>
      <div className='border-2 rounded-xl my-5'>
        <div className='flex justify-between mx-5 mt-5'>
          <p className=' font-semibold text-2xl'>Forms</p>
          <div className='flex gap-3'>
            <button onClick={() => setIsAddEditFormModalOpen(true)} className='bg-primary text-white rounded-lg py-2 px-4 flex items-center gap-1'><Add />Add Form</button>
          </div>
        </div>
        <div className='my-6'>
          <SkeletonTable columns={columns} data={formInformationList} isLoading={isLoading} />
        </div>
      </div>

      {/* Add Form */}
      <ModalComponent isOpen={isAddEditFormModalOpen} setIsOpen={setIsAddEditFormModalOpen} width={500} >
        <p className='mb-4 text-lg font-semibold text-center'>{!isEditForm ? 'Add Form' : 'Edit Form'}</p>
        <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={handleAddEditForm}>
          <Form.Item name="name" className='mt-6' rules={[{ required: true, message: 'Please enter form name!' }]}>
            <Input placeholder='Enter form name' size='large' />
          </Form.Item>
          <div className='text-center'>
            <LoadableButton
              className='bg-primary text-white uppercase text-base font-semibold mt-2 py-2 rounded-lg px-4'
              type='submit'
              lable={!isEditForm ? 'Submit' : 'Update'}
              loadingLable={!isEditForm ? 'Adding form...' : 'Updating form...'}
              isLoading={isAddEditFormLoading}
            />
          </div>
        </Form>
      </ModalComponent >

        {/* Delete form */}
        <ConfirmationModal
        isOpen={isFormDelete}
        setIsOpen={setIsFormDelete}
        message='Are you sure, want to delete this form?'
        onConfirm={() => handleDeleteCell()}
        isLoading={isLoadingDelete}
        loadingLabel='Removing form...'
      />
    </div>
  )
}