import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/team/team.js';
import { Button, Form, Input, Spin, Switch } from 'antd';
import Edit from '../../assets/Edit.jsx';
import Add from '../../assets/Add.jsx';
import ModalComponent from '../../components/modals/ModalComponent.jsx';
import ConfirmationModal from '../../components/modals/ConfirmationModal.jsx';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import { AddNewTeamMemberApi, GetTeamMemberListApi, GetEmployeeTaskListApi, ChangeEmployeeStatusApi, AssignPermissionApi, GetEmployeePermissionApi, UpdateAssignedPermissionApi } from '../../api/request/team/team.js';
import toast from 'react-hot-toast';
import { setSelectionRange } from '@testing-library/user-event/dist/utils/index.js';

export default function Team() {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTaskList, setIsLoadingTaskList] = useState(false);
    const [isLoadingPermissionAssign, setIsLoadingPermissionAssign] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({ name: '', email: '', password: '' });
    const [selectedTeamRole, setSelectedTeamRole] = useState(null);
    const [isLoadingAddTeam, setIsLoadingAddTeam] = useState(false);
    const [isAddPermission, setIsAddPermission] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [data, setData] = useState([]);
    const [assignedPermission, setAssignedPermission] = useState([]);
    const [employeePermission, setEmployeePermission] = useState([]);

    const getTeamMemberList = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetTeamMemberListApi();
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        status: (<Switch checked={item?.status == 'active' ? true : false} onClick={() => handleEmployeeStatus(item)} checkedChildren='On' unCheckedChildren='Off' />),
                        action: <div className='cursor-pointer' onClick={() => handleRoleModal(item)}><Edit /></div>
                    };
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTeamMemberList();
    }, []);

    const handleEmployeeStatus = (employee) => {
        setSelectedEmployee(employee);
        setIsStatusModalOpen(true);
    }

    const getEmployeePermissions = async (id) => {
        try {
            const { data } = await GetEmployeePermissionApi({ emp_id: id });
            if (data?.status) {
                setAssignedPermission(data?.data);
                return data?.data;
            }
        } catch (error) {
            return [];
        }
    }

    const togglePermission = (id) => {
        setEmployeePermission(employeePermission?.map(item => {
            if (item?.id === id) {
                return { ...item, permission: !item.permission };
            }
            return item;
        }))
    };

    const handleRoleModal = async (employee) => {
        try {
            setIsLoadingTaskList(true);
            setIsRoleModalOpen(true);
            setSelectedEmployee(employee);
            let assignedValueData = await getEmployeePermissions(employee?.id);
            let assignedValueArray = assignedValueData[0]?.task_id;
            let assignedValue = assignedValueArray?.split(",");
            const { data } = await GetEmployeeTaskListApi();
            if (data?.status) {
                setEmployeePermission(data?.data?.map(item => {
                    const hasPermission = assignedValue?.some(secondItem => secondItem === item.id);
                    return { ...item, permission: hasPermission };
                })?.sort((a, b) => a.field_order - b.field_order));
            }
            setIsLoadingTaskList(false);
        } catch (error) {
            setIsLoadingTaskList(false);
        }
    }

    const submitHandler = async (values) => {
        try {
            setIsLoadingAddTeam(true);
            const { data } = await AddNewTeamMemberApi(values);
            if (data?.status) {
                toast.success(data?.message);
                getTeamMemberList();
                setIsModalOpen(false);
                setIsAddPermission(true);
                handleRoleModal(data?.data);
            }
            setIsLoadingAddTeam(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingAddTeam(false);
        }
    }

    const assignPermission = async () => {
        const getPermissionIds = () => {
            return employeePermission
                .filter(item => item.permission === true) // Filter items with permission true
                .map(item => item.id) // Extract the id
                .join(","); // Join ids into a comma-separated string
        };

        try {
            setIsLoadingPermissionAssign(true);
            let params = {
                employee_id: selectedEmployee?.id,
                task_master_id: getPermissionIds()
            }
            const { data } = isAddPermission ? await AssignPermissionApi(params) : await UpdateAssignedPermissionApi(params);
            if (data?.status) {
                setIsRoleModalOpen(false);
                toast.success(data?.message);
                setSelectedEmployee(null);
                setSelectionRange(null);
                if (isAddPermission) {
                    getTeamMemberList();
                }
                setIsAddPermission(false);
            }
            setIsLoadingPermissionAssign(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsAddPermission(false);
            setIsLoadingPermissionAssign(false);
        }
    }

    const handleStatuChange = async () => {
        try {
            setIsStatusLoading(true);
            let params = {
                id: selectedEmployee?.id,
                status: selectedEmployee?.status == 'active' ? 'inactive' : 'active'
            };
            const { data } = await ChangeEmployeeStatusApi(params);
            if (data?.status) {
                getTeamMemberList();
                setIsStatusModalOpen(false);
                toast.success(data?.message);
                setSelectedEmployee(null);
            }
            setIsStatusLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsStatusLoading(false);
        }
    }

    const hasPermission = () => {
        return employeePermission?.some(item => item.permission === true);
    };

    useEffect(() => {
        if (!isRoleModalOpen) {
            setSelectedTeamRole(null);
            setSelectedEmployee(null);
            setIsAddPermission(false);
        }
    }, [isRoleModalOpen]);

    useEffect(() => {
        if (!isModalOpen) {
            form.resetFields();
        }
    }, [isModalOpen]);

    return (
        <div className='mb-6'>
            <div className='border-2 rounded-xl'>
                <div className='flex justify-between mx-5 gap-5 mt-4'>
                    <p className='font-semibold text-xl'>Team</p>
                    <Button type='primary' className='uppercase' icon={<Add />} onClick={() => setIsModalOpen(true)}>Add</Button>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>

            {/* ------ Create team modal ------- */}
            <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen} title='Add Team Member'>
                <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={submitHandler}>
                    <Form.Item name='name' className='mb-0 mt-1' rules={[{ required: true, message: 'Please enter name.', },]}>
                        <Input placeholder='Name' size='large' />
                    </Form.Item>
                    <Form.Item name='email' className='mb-0 mt-5' rules={[{ required: true, type: 'email', message: 'Please enter email.', },]}>
                        <Input placeholder='Email' size='large' />
                    </Form.Item>
                    <Form.Item name='password' className='mb-0 mt-5' rules={[{ required: true, message: 'Please enter password.', },]}>
                        <Input.Password placeholder='Password' size='large' />
                    </Form.Item>
                    <div className='flex justify-center mt-5'>
                        <LoadableButton
                            className='bg-primary text-white uppercase text-base font-semibold px-6 py-2 rounded-lg mt-2'
                            type='submit'
                            lable='Submit'
                            loadingLable='Adding Member...'
                            isLoading={isLoadingAddTeam}
                        />
                    </div>
                </Form>
            </ModalComponent>

            {/* ------ Employee roll modal ------- */}
            <ModalComponent isOpen={isRoleModalOpen} setIsOpen={setIsRoleModalOpen} title='Employee Permissions' width={600}>
                <div>
                    {isLoadingTaskList ? (
                        <div className="flex justify-center flex-col my-[10%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <div>
                            <div className='grid grid-cols-2 sm:grid-cols-3 gap-5'>
                                {
                                    employeePermission?.map((option, index) => (
                                        <div
                                            key={index}
                                            className={`p-3 font-medium border-2 rounded-md cursor-pointer duration-500 ${option?.permission ? 'text-primary border-primary bg-primaryLight' : 'text-secondaryLight1'}`}
                                            onClick={() => togglePermission(option?.id)}
                                        >
                                            <div className={`mx-auto flex items-center justify-center size-9 rounded-md ${option?.permission ? 'bg-white' : 'bg-primaryLight'}`}>
                                                <p className='font-bold text-primary text-lg'>{option?.field_order}</p>
                                            </div>
                                            <p className='text-center text-black mt-3'>{option?.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='flex justify-center mt-6'>
                                <LoadableButton
                                    className='bg-primary text-white uppercase text-base font-semibold px-6 py-2 rounded-lg mt-2'
                                    type='submit'
                                    lable='Assign Permission'
                                    onClick={assignPermission}
                                    disabled={!hasPermission()}
                                    loadingLable='Assigning permission...'
                                    isLoading={isLoadingPermissionAssign}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ModalComponent>

            {/* ------ Confirmation modal for status ------- */}
            <ConfirmationModal
                isOpen={isStatusModalOpen}
                setIsOpen={setIsStatusModalOpen}
                message='Are you sure, you want to change employee status?'
                onConfirm={handleStatuChange}
                isLoading={isStatusLoading}
                loadingLabel='Changing status...'
            />
        </div>
    )
}