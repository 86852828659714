import React, { useState } from 'react';
import MySegmented from '../../../components/segmented/Segmented.jsx';
import Cells from './components/Cells.jsx';
import Forms from './components/Forms.jsx';

export default function CellsAndForms() {

  const [segmentedValue, setSegmentedValue] = useState('Cells');

  return (
    <div>
      <MySegmented options={['Cells', 'Forms']} value={segmentedValue} setValue={setSegmentedValue} />
      <div className='mt-4'>{segmentedValue === 'Cells' ? (<Cells />) : (<Forms />)}</div>
    </div>
  )
}