import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../components/table/SkeletonTable.jsx';
import columns from '../../../columns/prepareTax/viewPrepareTax.js';
import { Button, Progress } from 'antd';
import ModalComponent from '../../../components/modals/ModalComponent.jsx';
import { GetOrganizerAnswerApi } from '../../../api/request/taskManager/taskManager.js';
import { useParams } from 'react-router-dom';

export default function ViewPrepareTax() {

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
    const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
    const [data, setData] = useState([]);

    const getAnswer = async () => {
        try {
            setIsLoading(true);
            let params = { organizer_id: id };
            const { data } = await GetOrganizerAnswerApi(params);
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        question: <div className={`${!item?.reviewed_checked ? 'font-semibold highlight-text' : ''}`}>{item?.field_label || '--'}</div>,
                        review: <div className='cursor-pointer'><img className='size-7' src={item?.reviewed_checked ? '/assets/icons/outlined-check-circle.svg' : '/assets/icons/outlined-circle.svg'} /></div>
                    };
                }));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAnswer();
    }, []);

    const fileTax = () => {
        setIsProcessModalOpen(true);
        setTimeout(() => {
            setIsProcessModalOpen(false);
            setIsSuccessModalOpen(true);
        }, 2000);
    }

    return (
        <div className='mb-6'>
            <div className='border-2 rounded-xl'>
                <div className='flex justify-between mx-5 gap-5 mt-4'>
                    <p className='font-semibold text-xl'>2023 Individual Tax Organizer</p>
                    <Button type='primary' className='uppercase font-semibold' onClick={fileTax}>File the tax</Button>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>

            {/* ------- Tax file process modal --------- */}
            <ModalComponent isOpen={isProcessModalOpen} setIsOpen={setIsProcessModalOpen} closable={false} width={730}>
                <div className='md:px-6'>
                    <img src='/assets/images/tax-process.png' />
                </div>
                <div className='w-full grid sm:grid-cols-2 md:gap-16 gap-5 mt-4'>
                    <div className='text-center'>
                        <p className='text-lg font-semibold'>How may I help you today?</p>
                        <p className='mt-1 text-sm text-secondaryLight2'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                    </div>
                    <div className='text-center'>
                        <p className='text-lg font-semibold'>How may I help you today?</p>
                        <p className='mt-1 text-sm text-secondaryLight2'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                    </div>
                </div>
                <div className='border-2 rounded-lg mt-8 p-4'>
                    <p className='text-lg font-semibold'>2023 Individual Tax Organizer</p>
                    <p className='text-lg text-secondaryLight2'>Winnie McGuire</p>
                    <Progress percent={30} showInfo={false} strokeColor={primaryColor} className='mt-3' />
                </div>
            </ModalComponent>

            {/* ------- Tax file completed modal --------- */}
            <ModalComponent isOpen={isSuccessModalOpen} setIsOpen={setIsSuccessModalOpen} closable={false}>
                <div className='flex justify-center'>
                    <img src='/assets/images/tax-file-completed.png' />
                </div>
                <div className='mt-4'>
                    <div className='text-center'>
                        <p className='text-2xl font-bold'>Successfully Completed</p>
                        <p className='mt-1 text-sm text-secondaryLight2'>Using this software, you can ask your questions and artificial intelligence assistant</p>
                    </div>
                </div>
                <div className='mt-6 flex justify-center'>
                    <Button type='primary' className='uppercase font-semibold' onClick={() => setIsSuccessModalOpen(false)}>Back to organizer</Button>
                </div>
            </ModalComponent>
        </div>
    )
}
