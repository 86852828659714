import React, { useState } from 'react';
import { Input, Spin } from 'antd';
import { AddUpdateQuestionApi } from '../../../api/request/organizer.js';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import CellDropdown from '../components/CellDropdown.jsx';

export default function YesNo({ question }) {

    const [value, setValue] = useState(question?.field_label);
    const [options, setOptions] = useState([{ id: '1', value: 'Yes' }, { id: '2', value: 'No' }]);
    const [isCompleted, setIsCompleted] = useState(value?.length ? true : false);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        setValue(event?.target?.value);
        setIsCompleted(false);
    }

    const handleSubmit = async (item) => {
        if (!value) { return; }
        try {
            setIsLoading(true);
            let questionOption = Array.isArray(item) ? item : options;
            let params = {
                question_id: question?.id,
                section_id: question?.organizer_section_id,
                field_label: value,
                options: questionOption?.map(item => item.value)?.filter((item) => item !== null)
            };
            const { data } = await AddUpdateQuestionApi(params);
            if (data?.status) {
                setIsCompleted(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className='my-2'>
            <div className='flex items-center gap-4'>
                <img src='/assets/icons/double-ellipsis-vertical.svg' className='cursor-grab' />
                <Input
                    className='py-3 ps-4'
                    size='large'
                    placeholder='Select yes or no'
                    onChange={onChange}
                    value={value}
                    onBlur={handleSubmit}
                />
                <CellDropdown question={question} setIsLoading={setIsLoading} />
                <div className='h-[22px] w-[22px]'>
                    {isLoading ? (
                        <Spin indicator={<LoadingOutlined spin />} className='size-[22px]' />
                    ) : (
                        <div className='size-[22px]'>{isCompleted ? <img className='size-6' src='/assets/icons/check-circle.svg' alt='icon' /> : <img className='size-[22px]' src='/assets/icons/x-circle.svg' alt='icon' />}</div>
                    )}
                </div>
            </div>
        </div>
    )
}