import Dashboard from "../../../../assets/Dashboard.jsx";
import Organizer from "../../../../assets/Organizer.jsx";
import Team from "../../../../assets/Team.jsx";
import ReviewDoc from "../../../../assets/ReviewDoc.jsx";
import PrepareTax from "../../../../assets/PrepareTax.jsx";
import ReviewTax from "../../../../assets/ReviewTax.jsx";
import FollowUp from "../../../../assets/FollowUp.jsx";
import ReturnToGov from "../../../../assets/ReturnToGov.jsx";
import Clients from "../../../../assets/Clients.jsx";
import Setting from "../../../../assets/Setting.jsx";
import Communication from "../../../../assets/Communication.jsx";
import Disc from "../../../../assets/Disc.jsx";
import BusinessTask from "../../../../assets/BusinessTask.jsx";
import Templates from "../../../../assets/Templates.jsx";

export const SidebarData = [
    {
        title: 'Dashboard',
        icon: <Dashboard />,
        url: '/dashboard',
        activeUrl: ['/dashboard']
    },
    {
        title: 'Organizers',
        icon: <Organizer />,
        url: '/organizers',
        activeUrl: ['/organizers']
    },
    {
        title: 'Review Doc',
        icon: <ReviewDoc />,
        url: '/review_doc',
        activeUrl: ['/review_doc']
    },
    {
        title: 'Prepare Tax',
        icon: <PrepareTax />,
        url: '/prepare_tax',
        activeUrl: ['/prepare_tax']
    },
    {
        title: 'Review Tax',
        icon: <ReviewTax />,
        url: '/review_tax',
        activeUrl: ['/review_tax']
    },
    {
        title: 'Follow Up',
        icon: <FollowUp />,
        url: '/follow_up',
        activeUrl: ['/follow_up']
    },
    {
        title: 'Return to Gov',
        icon: <ReturnToGov />,
        url: '/return_to_gov',
        activeUrl: ['/return_to_gov']
    },
    {
        title: 'Communication',
        icon: <Communication />,
        url: '/communication',
        activeUrl: ['/communication']
    },
    {
        title: 'Clients',
        icon: <Clients />,
        url: '/clients',
        activeUrl: ['/clients', '/client']
    },
    {
        title: 'Team',
        icon: <Team />,
        url: '/team',
        activeUrl: ['/team']
    },
    {
        title: 'Task',
        icon: <BusinessTask />,
        url: '/task',
        activeUrl: ['/task']
    },
    {
        title: 'Templates',
        icon: <Templates />,
        activeUrl: ['/organizer_template', 'invoice_template'],
        children: [
            {
                title: 'Organizer',
                icon: <Disc />,
                url: '/organizer_template',
                activeUrl: ['/organizer_template'],
            },
            {
                title: 'Invoice',
                icon: <Disc />,
                url: '/invoice_template',
                activeUrl: ['/invoice_template'],
            },
        ],
    },
    {
        title: 'Settings',
        icon: <Setting />,
        activeUrl: ['/cells_and_forms', 'invoice_template'],
        children: [
            {
                title: 'Cells & Forms',
                icon: <Disc />,
                url: '/cells_and_forms',
                activeUrl: ['/cells_and_forms'],
            },
            {
                title: 'Tags',
                icon: <Disc />,
                url: '/tags',
                activeUrl: ['/tags'],
            },
            {
                title: 'Provinces',
                icon: <Disc />,
                url: '/provinces',
                activeUrl: ['/provinces'],
            },
        ],
    },
];