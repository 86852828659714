import React, { useEffect, useState } from 'react'
import ModalComponent from '../../../components/modals/ModalComponent'
import LoadableButton from '../../../components/buttons/LoadableButton'
import { Form, Input } from 'antd'
import { CreateOrganizerApi, OrganizerTypeListApi } from '../../../api/request/organizer'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

const OrganizerInit = () => {
    
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [organizerTypeList, setOrganizerTypeList] = useState([]);
    const initialValues = { organizerName: "" };
    const navigate = useNavigate();

    const addOrganizer = async (value) => {
        try {
            setIsLoading(true)
            debugger;
            const { data } = await CreateOrganizerApi(value);
            if (data.status) {
                setIsLoading(false);
                setIsOpen(false);
                navigate('/create-organizer');
                toast.success(data.message);
            }
        } catch (err) { }
    }

    const getDocumentTypeList = async () => {
        try {
            const { data } = await OrganizerTypeListApi();
            if (data.status) {
                setOrganizerTypeList(data?.data || []);
            }
        } catch (err) { }
    }

    useEffect(() => {
        getDocumentTypeList();
    }, [])

    return (
        <>
            <div className="flex">
                <button className='ms-auto m-2 px-4 py-2 bg-primary text-white rounded-md' onClick={e => setIsOpen(true)}>Create Organizer</button>
            </div>
            <div className='grid grid-cols-4 mt-4 gap-5'>
                {
                    organizerTypeList?.map(item => {
                        return (
                            <div className='border rounded-md py-3 px-4'>
                                <div className="flex justify-between items-center">
                                    <h2>{item?.organizer_name}</h2>
                                    <button className='bg-primary px-2 py-1 rounded-md text-white' onClick={e => navigate(`/organizer_template/${item?.id}`)}>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title={"Enter Organizer Name"}>
                <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={addOrganizer}>
                    <Form.Item name="organizer_name" rules={[{ required: true, message: 'Please enter organizername!', },]}>
                        <Input placeholder='Enter Organizer name...' size='large' />
                    </Form.Item>
                    <div className='flex justify-center '>
                        <LoadableButton
                            className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-2'
                            type='submit'
                            lable='Submit'
                            loadingLable='Creating Organizer...'
                            isLoading={isLoading}
                        />
                    </div>
                </Form>
            </ModalComponent>
        </>
    )
}

export default OrganizerInit