import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function RadialBarChart({ values, labels = [], colors, height, width, centered = true, value = false, name = false }) {
    const series = values;
    const options = {
        chart: {
            type: 'radialBar',
            offsetX: 0,
            offsetY: 0,
            toolbar: {
                show: false // Remove toolbar to save space
            }
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 360,
                hollow: {
                    margin: 0,
                    size: '60%',
                    background: 'transparent',
                },
                dataLabels: {
                    name: { show: name, offsetY: -10 },
                    value: {
                        show: value,
                        fontSize: '22px',
                        fontWeight: 700,
                        offsetY: 10,
                        formatter: function (val) {
                            return `${val}%`;
                        }
                    }
                },
            }
        },
        grid: {
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            } // Remove all grid padding
        },
        colors: colors,
        labels: labels,
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    };
    return (
        <div>
            <ReactApexChart options={options} series={series} type="radialBar" height={height || 385} width={width || '100%'} className={`${centered ? 'flex justify-center' : null}`} />
        </div>
    )
}