import React, { useEffect, useState } from 'react';
import { AddClientTagApi, GetClientsApi } from '../../api/request/clients/clients';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/clients/clients.js';
import Eye from '../../assets/Eye.jsx';
import moment from 'moment';
import { Switch, Select, Tag, Spin } from 'antd';
import ConfirmationModal from '../../components/modals/ConfirmationModal.jsx';
import { useNavigate } from 'react-router-dom';
import { GetTagListApi } from '../../api/request/settings/tags.js';
import { LoadingOutlined } from '@ant-design/icons';

export default function Clients() {

  const navigate = useNavigate();
  const primaryLightColor = (getComputedStyle(document.documentElement).getPropertyValue('--primaryLight'))?.trim();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag color={primaryLightColor} onMouseDown={onPreventMouseDown} style={{ marginInlineEnd: 4 }} className='flex items-center gap-2'>
        <p className='text-primary'>{label}</p> <p className='cursor-pointer text-secondaryLight1' onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
          <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
        </svg>
        </p>
      </Tag>
    );
  };

  const getClient = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetClientsApi();
      if (data?.status) {
        setData(data?.data?.map((item) => {
          return {
            ...item,
            tag: (<Select mode='tags' tagRender={tagRender} placeholder='Select tag' style={{ width: '100%' }} defaultValue={item?.client_tag ? [item?.client_tag] : []} options={tags} onChange={(value) => tagHandler(item?.id, value, item?.client_tag)} />),
            statusTag: (<Switch checked={item?.status == 'active' ? true : false} onClick={() => handleClientStatus(item)} checkedChildren='On' unCheckedChildren='Off' />),
            created_at: item?.created_at ? (moment(item?.created_at).format('LL')) : '',
            action: <div className='cursor-pointer' onClick={() => navigate(`/client/${item?.id}`)}><Eye /></div>
          };
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getClientTags = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetTagListApi({ type: 'client' });
      if (data.status) {
        setTags(data?.data?.map((item) => { return { value: item?.name } }));
      }
      setIsLoading(false);
    }
    catch (error) { }
  }

  useEffect(() => {
    getClientTags();
  }, [])

  useEffect(() => {
    getClient();
  }, [tags])

  const handleClientStatus = (item) => {
    setSelectedClient(item);
    setIsStatusModalOpen(true);
  }

  const handleStatuChange = async () => {
    try {

    } catch (error) {

    }
  }

  const spinner = <>
    <div className="flex justify-center items-center my-1">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: "$theme"
            }}
            spin
          />
        }
      />
    </div>
  </>

  const tagHandler = async (id, tagArray, currentTag) => {
    const findNonMatchingString = () => {
      const nonMatching = tagArray.filter(item => item !== currentTag);
      return nonMatching.length > 0 ? nonMatching[0] : null;
    };

    try {
      let tag = findNonMatchingString();
      setData(prev => {
        return prev.map(item => {
          if (item.id == id) {
            return { ...item, tag: spinner };
          } else {
            return { ...item }
          }
        })
      });
      let params = { id: id, tag: tag };
      const { data } = await AddClientTagApi(params);
      if (data?.status) {
        setData(prev => {
          return prev.map(item => item.id === id ?
            {
              ...item,
              client_tag: tag,
              tag: (<Select mode='tags' tagRender={tagRender} placeholder='Select tag' style={{ width: '100%' }} defaultValue={[tag]} options={tags} onChange={(value) => tagHandler(item?.id, value, tag)} />),
              statusTag: (<Switch checked={item?.status == 'active' ? true : false} onClick={() => handleClientStatus(item)} checkedChildren='On' unCheckedChildren='Off' />),
              created_at: item?.created_at ? (moment(item?.created_at).format('LL')) : '',
              action: <div className='cursor-pointer' onClick={() => navigate(`/client/${item?.id}`)}><Eye /></div>
            } : item)
        });
      }
    } catch (error) {

    }
  }

  return (
    <div>
      <div className='border-2 rounded-xl mb-4'>
        <div className='mt-4 mx-5'>
          <p className='font-semibold text-xl'>Clients</p>
        </div>
        <div className='table-radius-0 text-light my-4'>
          <SkeletonTable columns={columns} data={data} isLoading={isLoading} pagination={true} />
        </div>
      </div>

      {/* ------ Confirmation modal for status ------- */}
      <ConfirmationModal
        isOpen={isStatusModalOpen}
        setIsOpen={setIsStatusModalOpen}
        message='Are you sure, you want to change client status?'
        onConfirm={handleStatuChange}
        isLoading={isStatusLoading}
        loadingLabel='Changing status...'
      />
    </div>
  )
}