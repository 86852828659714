import React from 'react';

export default function Dashboard({ active }) {

  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1048_501" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
        <rect width="26" height="26" fill="white" />
      </mask>
      <g mask="url(#mask0_1048_501)">
        <path d="M11.1081 6.37491L11.4097 10.8599L11.5594 13.1141C11.561 13.3459 11.5973 13.5762 11.6674 13.7975C11.8482 14.2272 12.2833 14.5002 12.7566 14.4811L19.9685 14.0093C20.2809 14.0042 20.5825 14.121 20.8069 14.3341C20.994 14.5117 21.1148 14.7439 21.153 14.9938L21.1657 15.1454C20.8673 19.278 17.8321 22.725 13.7081 23.6147C9.58398 24.5044 5.35498 22.6249 3.31707 18.9963C2.72956 17.9422 2.36259 16.7835 2.23771 15.5883C2.18555 15.2345 2.16258 14.8771 2.16903 14.5196C2.16258 10.089 5.31773 6.25858 9.73433 5.33516C10.2659 5.25238 10.787 5.53379 11.0002 6.0187C11.0553 6.131 11.0917 6.25127 11.1081 6.37491Z" stroke={`${active ? primaryColor : 'black'}`} stroke-width="2" />
        <path d="M21.6672 10.031L21.6617 10.0584L21.6459 10.0982L21.6481 10.2075C21.64 10.3523 21.5879 10.4916 21.4984 10.6042C21.4049 10.7214 21.2774 10.8013 21.1369 10.8323L21.0513 10.8449L15.0477 11.263C14.8479 11.2842 14.6491 11.215 14.5007 11.0727C14.3769 10.9539 14.2978 10.7938 14.2754 10.6212L13.8725 4.17685C13.8654 4.15506 13.8654 4.13144 13.8725 4.10965C13.8779 3.93201 13.9507 3.76411 14.0744 3.64344C14.1981 3.52277 14.3625 3.4594 14.5308 3.46749C18.095 3.56496 21.0906 6.32008 21.6672 10.031Z" stroke={`${active ? primaryColor : 'black'}`} stroke-width="2" />
      </g>
    </svg>
  )
}
