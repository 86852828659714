const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Question',
        dataIndex: 'question',
        render: defaultRender,
    },
    {
        title: 'Answer',
        dataIndex: 'answer',
        render: defaultRender,
    },
    {
        title:'Review',
        dataIndex: 'review',
    },
];

export default columns;