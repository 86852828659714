import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/reviewTax/reviewTax.js';
import viewTaxColumns from '../../columns/reviewTax/viewReviewTax.js';
import Eye from '../../assets/Eye.jsx';
import ModalComponent from '../../components/modals/ModalComponent.jsx';
import { Button } from 'antd';
import { GetTaskWiseOrganizerApi } from '../../api/request/taskManager/taskManager.js';

export default function ReturnToGov() {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [organizerData, setOrganizerData] = useState([
        { id: '3ab1f6e3-4cb2-4d95-9140-fb0d7332bc31', question: 'What do you want to name your comapny?', answer: 'Specific name', review: true },
        { id: '3ab1f1e3-4cb2-4s95-9140-fb0d7332bc31', question: 'Date you want the incorporation to be active', answer: '24 jun 2023', review: true },
        { id: '3ab1f6e3-4cb2-4d95-9110-fb0d7332bc31', question: 'What product/services the business will provide?', answer: 'Yes', review: true },
        { id: '3ab1f6e3-4cb2-4d95-9940-fb0d7332bc31', question: 'What do you want to name your comapny?', answer: 'Specific name', review: true },
        { id: '3ab1f6g3-4cb2-4d95-9150-fb0d7332bc31', question: 'Date you want the incorporation to be active', answer: '24 jun 2023', review: true },
        { id: '3ab1f6e3-4cb2-4d95-9140-fb0d7332bc81', question: 'What do you want to name your comapny?', answer: 'Specific name', review: true },
        { id: '3ab1f6e3-4cb2-4d44-9140-fb0d7332bc31', question: 'Date you want the incorporation to be active', answer: '24 jun 2023', review: true },
        { id: '3ab1f6e3-4cb2-4d11-9140-fb0d7332bc31', question: 'What product/services the business will provide?', answer: 'Yes', review: true },
    ]);

    const getOrganizers = async () => {
        try {
            setIsLoading(true);
            let params = {
                task_order: 'task5'
            };
            const { data } = await GetTaskWiseOrganizerApi(params);
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        organizer: <div className='font-bold highlight-text'>{item?.organizer}</div>,
                        action: <div className='cursor-pointer' onClick={() => setIsOpenModal(true)}><Eye /></div>
                    };
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizers();
        setOrganizerData(organizerData?.map((item) => {
            return {
                ...item,
                question: <div className={`${!item?.review ? 'font-semibold highlight-text' : ''}`}>{item?.question}</div>,
                review: <div className='cursor-pointer'><img className='size-7' src={item?.review ? '/assets/icons/outlined-check-circle.svg' : '/assets/icons/outlined-circle.svg'} /></div>
            };
        }))
    }, []);

    return (
        <div>
            <div className='border-2 rounded-xl'>
                <div className='mt-4 mx-5'>
                    <p className='font-semibold text-xl'>Organizers</p>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>

            <ModalComponent isOpen={isOpenModal} setIsOpen={setIsOpenModal} closable={false} title='2023 Individual Tax Organizer' width={900}>
                <div className='mt-2 pb-5 border rounded-lg text-light'>
                    <SkeletonTable columns={viewTaxColumns} data={organizerData} isLoading={isLoading} />
                </div>
                <div className='flex justify-center mt-5'>
                    <Button type='primary' className='uppercase font-semibold' onClick={() => setIsOpenModal(false)}>Complete task</Button>
                </div>
            </ModalComponent>
        </div>
    )
}