const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Mobile No',
        dataIndex: 'mobile_no',
        render: defaultRender,
    },
    {
        title: 'Role',
        dataIndex: 'role',
        render: defaultRender,
    },
    {
        title: 'Tag',
        dataIndex: 'tag',
        render: defaultRender,
        width: 200
    },
    {
        title: 'Status',
        dataIndex: 'statusTag',
        render: defaultRender,
    },
    {
        title: 'Created Date',
        dataIndex: 'created_at',
        render: defaultRender,
    },
    {
        title:'Action',
        dataIndex: 'action',
    },
];

export default columns;