import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { SidebarData } from './sidebarData/SidebarData.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeCookies } from "../../../services/Cookies.js";
import { LogoutApi } from '../../../api/request/auth.js';
import toast from 'react-hot-toast';
import ConfirmationModal from '../../modals/ConfirmationModal.jsx';
import { useDispatch } from 'react-redux';
import { setloggedIn } from "../../../store/Slices/userSlice.js";
import { Sidebar, Menu, SubMenu } from 'react-pro-sidebar';
import rolesSidebar from './sidebarData/rolesData.js';
import { useSelector } from 'react-redux';

export default function AppSidebar() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.user.userDetails);
    const { pathname } = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
    const [modulesPermission, setModulesPermission] = useState([]);

    const handleMenuClick = (index) => { setOpenSubMenuIndex(index); };

    const getFirstPath = (path) => {
        const parts = path.split('/');
        return parts.length > 1 ? '/' + parts[1] : path;
    }

    const isActivePath = (path) => {
        let active = false;
        path?.map((item) => {
            if (item == (getFirstPath(pathname))) {
                active = true;
            }
        })
        return active;
    }

    const logoutHandler = async () => {
        try {
            setIsLoading(true);
            const { data } = await LogoutApi();
            if (data?.status) {
                toast.success(data?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/login');
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            localStorage.clear();
            dispatch(setloggedIn(false));
            removeCookies('token');
            navigate('/login');
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let modulePermissions = [rolesSidebar['Dashboard']];
        userDetails?.permissions?.map((item) => {
            modulePermissions.push(rolesSidebar[item]);
        })
        setModulesPermission(modulePermissions);
    }, [userDetails?.id])

    return (
        <div className='sidebar-container w-[250px] fixed border-r-2'>
            <div className='border-b-2 p-4'>
                <p className='text-primary text-3xl font-bold text-center mt-1'>TaxTribe</p>
            </div>

            <div className='h-[calc(100vh-158px)] overflow-y-auto my-3'>
                {
                    (userDetails?.role === 'admin' ? SidebarData : modulesPermission).map((item, index) => {
                        const iconWithProps = (item?.icon ? React.cloneElement(item?.icon, { active: isActivePath(item?.activeUrl) }) : null)
                        return item?.children ? (
                            <Sidebar>
                                <Menu>
                                    <SubMenu key={index} open={openSubMenuIndex == index} onOpenChange={() => handleMenuClick(index == openSubMenuIndex ? null : index)} label={
                                        <div key={index} className={`flex items-center font-medium cursor-pointer py-2 ps-9 header`}>
                                            {item?.icon}
                                            <p className='text-base ms-3'>{item?.title}</p>
                                        </div>
                                    }>
                                        <div className='pt-1'>
                                            {
                                                item?.children?.map((childItem, index) => {
                                                    const childIconWithProps = React.cloneElement(childItem?.icon, { active: isActivePath(childItem?.activeUrl) })
                                                    return (
                                                        <div className='flex items-center gap-3 w-full mt-3'>
                                                            <div className={`w-1 h-10 rounded-e-md duration-200 ${isActivePath(childItem?.activeUrl) ? ' bg-primary' : null}`}>
                                                            </div>
                                                            <div key={index} onClick={() => navigate(childItem?.url)} className={`submenu child flex items-center cursor-pointer font-medium py-2 ps-10 mr-4 duration-200 w-full ${isActivePath(childItem?.activeUrl) ? 'text-primary rounded-lg font-semibold bg-primaryLight' : null}`}>
                                                                {childIconWithProps}
                                                                <p className='ms-2'>{childItem?.title}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </SubMenu>
                                </Menu>
                            </Sidebar>
                        ) : (
                            <div className='flex items-center gap-3 my-3 mb-5 mr-4'>
                                <div className={`w-1 h-10 rounded-e-md duration-200 ${isActivePath(item?.activeUrl) ? ' bg-primary' : null}`}>
                                </div>
                                <div key={index} onClick={() => { navigate(item?.url); handleMenuClick(index) }} className={`font-medium cursor-pointer duration-200 w-full ${isActivePath(item?.activeUrl) ? ' text-primary bg-primaryLight rounded-lg font-semibold' : null}`}>
                                    <button className='flex items-center w-full py-2 px-6'>
                                        {iconWithProps}
                                        <p className='text-base ms-3'>{item?.title}</p>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='px-4 pb-5'>
                <button onClick={() => setIsModalOpen(true)} className='flex items-center justify-center gap-3 bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg'>
                    <img src='/assets/icons/logout.svg' className='h-5' alt='icon' />
                    <p>Logout</p>
                </button>
            </div>

            {/* --- Loguot confirmation modal --- */}
            <ConfirmationModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                message='Are you sure you want to logout?'
                onConfirm={logoutHandler}
                isLoading={isLoading}
                loadingLabel='Loging out...'
            />
        </div>
    )
}