import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/businessTask/businessTask.js';
import { GetTaskListApi, GetEmployeeListApi, AssignTaskApi } from '../../api/request/businessTask/businessTask.js';
import moment from 'moment/moment.js';
import Edit from '../../assets/Edit.jsx';
import { Form, Select, Spin } from 'antd';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import ModalComponent from '../../components/modals/ModalComponent.jsx';
import toast from 'react-hot-toast';

export default function BusinessTask() {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isEmployeeListLoading, setIsEmployeeListLoading] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [selectedOrganizer, setSelectedOrganizer] = useState(null);
    const [initialValues, setInitialValues] = useState({ emp_id: null });

    const getTaskList = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetTaskListApi();
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        status: <div className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-500'} font-semibold uppercase`}>{item?.status}</div>,
                        created_at: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                        is_submit: item?.is_submit ? 'Yes' : 'No',
                        is_payment: item?.is_payment ? 'Yes' : 'No',
                        action: <div className='cursor-pointer' onClick={() => handleAssignModal(item)}><Edit /></div>
                    };
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTaskList();
    }, []);

    const getEmployees = async () => {
        try {
            setIsEmployeeListLoading(true);
            const { data } = await GetEmployeeListApi();
            if (data?.data) {
                setEmployees(data?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name
                    };
                }));
            }
            setIsEmployeeListLoading(false);
        } catch (error) {
            setIsEmployeeListLoading(false);
        }
    }

    const handleAssignModal = (item) => {
        getEmployees();
        setSelectedOrganizer(item);
        setIsAssignModalOpen(true);
    }

    useEffect(() => {
        if (!isAssignModalOpen) {
            form.resetFields();
        }
    }, [isAssignModalOpen]);

    const assignTaskHandler = async (values) => {
        try {
            setIsLoadingSubmit(true);

            let params = {
                ...values,
                organizer_id: selectedOrganizer?.id
            }

            const { data } = await AssignTaskApi(params);

            if (data?.status) {
                setIsAssignModalOpen(false);
                toast.success(data?.message);
            }

            setIsLoadingSubmit(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingSubmit(false);
        }
    }

    return (
        <div className='mb-6'>
            <div className='border-2 rounded-xl'>
                <div className='flex justify-between mx-5 gap-5 mt-4'>
                    <p className='font-semibold text-xl'>Business Task</p>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>

            <ModalComponent isOpen={isAssignModalOpen} setIsOpen={setIsAssignModalOpen} title='Assign Task'>
                {
                    isEmployeeListLoading ? (
                        <div className="flex justify-center flex-col my-[10%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={assignTaskHandler}>
                            <Form.Item name='emp_id' rules={[{ required: true, message: 'Please select employee!', },]}>
                                <Select placeholder='Select Employee' options={employees} size='large' />
                            </Form.Item>
                            <div className='flex justify-center '>
                                <LoadableButton
                                    className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-2'
                                    type='submit'
                                    lable='Assign Task'
                                    loadingLable='Assigning Task...'
                                    isLoading={isLoadingSubmit}
                                />
                            </div>
                        </Form>
                    )}
            </ModalComponent>
        </div>
    )
}