import React, { useEffect, useState } from 'react'
import SkeletonTable from '../../../components/table/SkeletonTable.jsx'
import columns from '../../../columns/settings/invoiceTemplates.js';
import ModalComponent from '../../../components/modals/ModalComponent.jsx';
import { Form, Input } from 'antd';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import { GetInvoiceTemplateListApi, AddUpdateInvoiceTemplateApi, DeleteInvoiceTemplateApi } from '../../../api/request/settings/invoiceTemplate.js';
import toast from 'react-hot-toast';
import Add from '../../../assets/Add.jsx';
import ConfirmationModal from '../../../components/modals/ConfirmationModal.jsx';
import Edit from '../../../assets/Edit.jsx';
import Delete from '../../../assets/Delete.jsx';
import moment from 'moment';
const { TextArea } = Input;

export default function InvoiceTemplate() {

  const [form] = Form.useForm();
  const initialValues = { name: '', amount: '', description: '' };
  const [isAddEditTemplateOpen, setIsAddEditTemplateOpen] = useState(false);
  const [isAddEditTemplateLoading, setIsAddEditTemplateLoading] = useState(false);
  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [isTemplateDelete, setIsTemplateDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [templates, setTemplates] = useState([]);

  const getTemplateList = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetInvoiceTemplateListApi();
      if (data?.status) {
        setTemplates(data?.data?.map((item) => {
          return {
            ...item,
            amount: `$ ${item?.amount}`,
            status: <div className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-500'} font-semibold uppercase`}>{item?.status}</div>,
            created_at: item?.created_at ? (moment(item?.created_at).format('LL')) : '',
            action: <div className='flex items-center gap-3'>
              <button onClick={() => handleEditModal(item)}><Edit /></button>
              <button onClick={() => { setIsTemplateDelete(true); setTemplateId(item?.id) }}><Delete /></button>
            </div>
          };
        }));
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTemplateList();
  }, []);

  const handleEditModal = (template) => {
    setIsAddEditTemplateOpen(true);
    setIsEditTemplate(true);
    setTemplateId(template.id);
    form.setFieldsValue({ name: template?.name, amount: template?.amount, description: template?.description });
  };

  const handleAddEditTemplate = async (values) => {
    try {
      setIsAddEditTemplateLoading(true);
      const params = isEditTemplate ? { ...values, invoice_temp_id: templateId } : values;
      const { data } = await AddUpdateInvoiceTemplateApi(params);
      if (data?.status) {
        setIsAddEditTemplateOpen(false);
        getTemplateList();
        toast.success(data?.message);
      }
      setIsAddEditTemplateLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsAddEditTemplateLoading(false);
    }
  }

  const handleDeleteCell = async () => {
    setIsLoadingDelete(true);
    try {
      const { data } = await DeleteInvoiceTemplateApi({ invoice_temp_id: templateId });
      if (data?.status) {
        setIsTemplateDelete(false);
        getTemplateList();
        toast.success(data?.message);
      }
      setIsLoadingDelete(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoadingDelete(false);
    }
  }

  return (
    <div>
      <div className='border-2 rounded-xl my-5'>
        <div className='flex justify-between mx-5 mt-5'>
          <p className=' font-semibold text-2xl'>Invoice Templates</p>
          <div className='flex gap-3'>
            <button onClick={() => setIsAddEditTemplateOpen(true)} className='bg-primary text-white rounded-lg py-2 px-4 flex items-center gap-1'><Add />Add Template</button>
          </div>
        </div>
        <div className='my-6'>
          <SkeletonTable columns={columns} data={templates} isLoading={isLoading} />
        </div>
      </div>

      {/* Add Template */}
      <ModalComponent isOpen={isAddEditTemplateOpen} setIsOpen={setIsAddEditTemplateOpen} width={500} >
        <p className='mb-4 text-lg font-semibold text-center'>{!isEditTemplate ? 'Add Template' : 'Edit Template'}</p>
        <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={handleAddEditTemplate}>

          <Form.Item name="name" className='mt-6' rules={[{ required: true, message: 'Please enter template name!' }]}>
            <Input placeholder='Enter template name' size='large' />
          </Form.Item>

          <Form.Item name="amount" className='mt-6' rules={[{ required: true, message: 'Please enter tax amount!' }]}>
            <Input placeholder='Enter tax amount' type='number' size='large' />
          </Form.Item>

          <Form.Item name="description" className='mt-6' rules={[{ required: true, message: 'Please enter template description!' }]}>
            <TextArea placeholder="Enter template description" autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>

          <div className='text-center'>
            <LoadableButton
              className='bg-primary text-white uppercase text-base font-semibold mt-2 py-2 rounded-lg px-4'
              type='submit'
              lable={!isEditTemplate ? 'Submit' : 'Update'}
              loadingLable={!isEditTemplate ? 'Adding template...' : 'Updating template...'}
              isLoading={isAddEditTemplateLoading}
            />
          </div>
        </Form>
      </ModalComponent >

      {/* Delete Template */}
      <ConfirmationModal
        isOpen={isTemplateDelete}
        setIsOpen={setIsTemplateDelete}
        message='Are you sure, want to delete this invoice template?'
        onConfirm={() => handleDeleteCell()}
        isLoading={isLoadingDelete}
        loadingLabel='Removing template...'
      />
    </div >
  )
}