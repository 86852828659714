import React, { useEffect, useState } from 'react'
import SkeletonTable from '../../../components/table/SkeletonTable'
import column from '../../../columns/settings/provinces.js';
import ModalComponent from '../../../components/modals/ModalComponent';
import { Form, Input, Switch } from 'antd';
import LoadableButton from '../../../components/buttons/LoadableButton';
import { GetProvinceListApi, AddUpdateProvinceApi, DeleteProvinceApi } from '../../../api/request/settings/provinces.js';
import toast from 'react-hot-toast';
import Add from '../../../assets/Add';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import Edit from '../../../assets/Edit';
import Delete from '../../../assets/Delete';
import moment from 'moment/moment.js';


export default function Province() {

    const [form] = Form.useForm();
    const initialProvinceValues = { state: '', rate_percentage: '' };
    const [isAddEditProvinceModalOpen, setIsAddEditProvinceModalOpen] = useState(false);
    const [isAddEditProvinceLoading, setIsAddEditProvinceLoading] = useState(false);
    const [isEditProvince, setIsEditProvince] = useState(false);
    const [isProvinceDelete, setIsProvinceDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);

    const handleFieldsChange = (_, allFields) => {
        const isChanged = allFields.some(field => field.touched);
        setIsFormChanged(isChanged);
    }

    const getProvincesList = async () => {
        setIsLoading(true);
        try {
            const { data } = await GetProvinceListApi();
            if (data.status) {
                setProvinces(data?.data?.map((item) => {
                    return {
                        ...item,
                        rate_percentage: `${Number(item?.rate_percentage)?.toFixed(2)} %`,
                        status: <div className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-500'} font-semibold uppercase`}>{item?.status}</div>,
                        created_at: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                        action: <div className='flex items-center gap-3'>
                            <button onClick={() => handleEditModal(item)}><Edit /></button>
                            <button onClick={() => { setSelectedProvince(item); setIsProvinceDelete(true) }}><Delete /></button>
                        </div>
                    }
                }));
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getProvincesList();
        setIsEditProvince(false);
    }, []);

    const handleEditModal = (province) => {
        setIsAddEditProvinceModalOpen(true);
        setIsEditProvince(true);
        setSelectedProvince(province);
        form.setFieldsValue({ state: province?.state, rate_percentage: province?.rate_percentage });
    }

    useEffect(() => {
        if (!isAddEditProvinceModalOpen) {
            form.resetFields();
        }
    }, [isAddEditProvinceModalOpen]);

    const handleAddEditProvince = async (values) => {
        try {
            setIsAddEditProvinceLoading(true);
            const params = isEditProvince ? { ...values, tax_rate_id: selectedProvince?.id, type: 'client' } : { ...values, type: 'client' };
            const { data } = await AddUpdateProvinceApi(params);
            if (data?.status) {
                setIsAddEditProvinceModalOpen(false);
                getProvincesList();
                form.resetFields();
                toast.success(data?.message);
                setIsFormChanged(false);
            }
            setIsAddEditProvinceLoading(false);
            setIsEditProvince(false);
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsAddEditProvinceLoading(false);
            setIsEditProvince(false);
        }
    }

    const handleDeleteProvince = async () => {
        setIsLoadingDelete(true);
        try {
            const { data } = await DeleteProvinceApi({ tax_rate_id: selectedProvince?.id });
            if (data?.status) {
                setIsProvinceDelete(false);
                getProvincesList();
                toast.success(data?.message);
            }
            setIsLoadingDelete(false);
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoadingDelete(false);
        }
    }

    return (
        <div>
            <div className='border-2 rounded-xl my-5'>
                <div className='flex justify-between mx-5 mt-5'>
                    <p className=' font-semibold text-2xl'>Province</p>
                    <div className='flex gap-3'>
                        <button onClick={() => setIsAddEditProvinceModalOpen(true)} className='bg-primary text-white rounded-lg py-2 px-4 flex items-center gap-1'><Add />Add Province</button>
                    </div>
                </div>
                <div className='my-6'>
                    <SkeletonTable columns={column} data={provinces} isLoading={isLoading} />
                </div>
            </div>

            {/* Add Provinces */}
            <ModalComponent isOpen={isAddEditProvinceModalOpen} setIsOpen={setIsAddEditProvinceModalOpen} width={500} >
                <p className='mb-4 text-lg font-semibold text-center'>{!isEditProvince ? 'Add Province' : 'Edit Province'}</p>
                <Form autoComplete='off' form={form} initialValues={initialProvinceValues} onFinish={handleAddEditProvince} onFieldsChange={handleFieldsChange}>
                    <Form.Item name="state" className='mt-6' rules={[{ required: true, message: 'Please enter province (state) name!' }]}>
                        <Input placeholder='Enter province (state) name' size='large' />
                    </Form.Item>
                    <Form.Item name="rate_percentage" className='mt-8' rules={[{ required: true, message: 'Please enter the tax rate!' }, () => ({
                        validator(_, value) {
                            if (value < 0) { return Promise.reject(new Error('Tax rate cannot be negative!')) }
                            return Promise.resolve()
                        },
                    })]}>
                        <Input placeholder='Enter tax rate' type='Number' size='large' />
                    </Form.Item>
                    <div className='text-center'>
                        <LoadableButton
                            className='bg-primary text-white uppercase text-base font-semibold mt-2 py-2 rounded-lg px-4'
                            type='submit'
                            lable={!isEditProvince ? 'Submit' : 'Update'}
                            loadingLable={!isEditProvince ? 'Adding province...' : 'Updating province...'}
                            isLoading={isAddEditProvinceLoading}
                            disabled={!isFormChanged || isAddEditProvinceLoading}
                        />
                    </div>
                </Form>
            </ModalComponent >

            {/* Delete province */}
            <ConfirmationModal
                isOpen={isProvinceDelete}
                setIsOpen={setIsProvinceDelete}
                message='Are you sure, want to delete this province?'
                onConfirm={() => handleDeleteProvince()}
                isLoading={isLoadingDelete}
                loadingLabel='Removing province...'
            />
        </div>
    )
}