import { Input, Tabs, Spin, Breadcrumb, Image, Upload, Dropdown, Button, Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { GetActiveChatApi, GetArchiveChatApi, GetMessageListApi, GetFolderListApi, GetChildFolderListApi, UploadFileFolderApi } from '../../api/request/communication/communication.js';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Cookies } from "react-cookie";
import { getSocket } from '../../services/Socket.js';
import ModalComponent from '../../components/modals/ModalComponent.jsx';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import columns from '../../columns/communication/attachment.js';

export default function Communication() {

    const [createFolderForm] = Form.useForm();
    const [uploadFileForm] = Form.useForm();
    const messagesEndRef = useRef(null);
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const [message, setMessage] = useState('');
    const userDetails = useSelector((state) => state.user.userDetails);
    const [isArchiveChatLoading, setIsArchiveChatLoading] = useState(false);
    const [isActiveChatLoading, setIsActiveChatLoading] = useState(false);
    const [archiveChatList, setArchiveChatList] = useState([]);
    const [activeChatList, setActiveChatList] = useState([]);
    const [isMessageListLoading, setIsMessageListLoading] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [isFolderListLoading, setIsFolderListLoading] = useState(false);
    const [folders, setFolders] = useState([]);
    const [data, setData] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [isOpenAttachmentModal, setIsOpenAttachmentModal] = useState(false);
    const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
    const [isFoldersLoading, setIsFoldersLoading] = useState(false);
    const [folderFormValue, setFolderFormValue] = useState({ name: '' });
    const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
    const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [uploadFileFormValue, setUploadFileFormValue] = useState({ files: [] });
    const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);

    const uploadItems = [
        {
            key: '1',
            label: <div onClick={() => setIsCreateFolderModalOpen(true)} className='flex items-center gap-2 font-semibold'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                Create Folder
            </div>
        },
        {
            key: '2',
            label: <div onClick={() => setIsOpenUploadModal(true)} className='flex items-center gap-2 font-semibold'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                </svg>
                Upload File
            </div>
        }
    ];

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        uploadFileForm.setFieldsValue({ files: newFileList });
        if (newFileList?.length == 0) {
            uploadFileForm.setFieldsValue({ files: [] });
        }
    }

    const UploadButton = (
        <button type="button" className='flex flex-col items-center gap-2'>
            <div className="size-7"><img src="/assets/icons/upload.svg" alt="icon" /></div>
            Upload
        </button>
    );

    // Default auto scroll for open chat
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messageList]);

    const handleTabsChange = (tab) => {
        setSelectedRoom(null);
        if (tab == '1') {
            getActiveChat();
        } else if (tab == '2') {
            getArchiveChat();
        }
    }

    const getActiveChat = async () => {
        try {
            setIsActiveChatLoading(true);
            const { data } = await GetActiveChatApi();
            if (data?.status) {
                setActiveChatList(data?.data);
            }
            setIsActiveChatLoading(false);
        } catch (error) {
            setIsActiveChatLoading(false);
        }
    }

    const getArchiveChat = async () => {
        try {
            setIsArchiveChatLoading(true);
            const { data } = await GetArchiveChatApi();
            if (data?.status) {
                setArchiveChatList(data?.data);
            }
            setIsArchiveChatLoading(false);
        } catch (error) {
            setIsArchiveChatLoading(false);
        }
    }

    useEffect(() => {
        getActiveChat();
    }, []);

    const renderActiveChat = () => {
        return (
            <div>
                {isActiveChatLoading ? (
                    <div className='flex flex-col items-center gap-2 my-28'>
                        <Spin />
                        <p className='text-secondaryLight1'>Loading...</p>
                    </div>
                ) : (
                    <div>
                        {activeChatList?.length > 0 ? (
                            <div>
                                {activeChatList?.map((chat, index) => (
                                    <div>
                                        <div key={index} className='border-b-2 border-t-2 py-3 text-secondaryLight1 px-4'>{chat?.title}</div>
                                        {chat?.chat_rooms?.map((item) => (
                                            <div key={item?.id} className='px-2'>
                                                <div className={`flex items-center gap-3 cursor-pointer p-2 my-3 rounded-md duration-300 hover:text-primary ${selectedRoom?.id == item?.id ? 'text-primary' : ' text-secondaryLight2'}`} onClick={() => setSelectedRoom(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                                    </svg>
                                                    <p className='font-medium line-clamp-1 capitalize'>{item?.subject}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className='text-secondaryLight1 text-center mt-20'>No Chat Found</p>
                        )}
                    </div>
                )}
            </div>
        )
    }

    const renderArchiveChat = () => {
        return (
            <div>
                {isArchiveChatLoading ? (
                    <div className='flex flex-col items-center gap-2 my-28'>
                        <Spin />
                        <p className='text-secondaryLight1'>Loading...</p>
                    </div>
                ) : (
                    <div>
                        {archiveChatList?.length > 0 ? (
                            <div className='px-2'>
                                {archiveChatList?.map((item) => (
                                    <div key={item?.id} className={`flex items-center gap-3 cursor-pointer p-2 my-3 rounded-md duration-300 hover:text-primary ${selectedRoom?.id == item?.id ? 'text-primary' : ' text-secondaryLight2'}`} onClick={() => setSelectedRoom(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                        </svg>
                                        <p className='font-medium line-clamp-1 capitalize'>{item?.subject}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className='text-secondaryLight1 text-center mt-20'>No Chat Found</p>
                        )}
                    </div>
                )}
            </div>
        )
    }

    const getMessageList = async () => {
        if (!selectedRoom) { return }
        try {
            setIsMessageListLoading(true);
            let params = {
                chat_room_id: selectedRoom?.id
            }
            const { data } = await GetMessageListApi(params);
            if (data?.status) {
                setMessageList(data?.data?.chat);
            }
            setIsMessageListLoading(false);
        } catch (error) {
            setIsMessageListLoading(false);
        }
    }

    useEffect(() => {
        getMessageList();
    }, [selectedRoom]);

    const sendMessage = async () => {
        if (!message?.trim()) {
            return;
        }

        let params = { token: token, chat_room_id: selectedRoom?.id, message: message, organizer_id: selectedRoom?.organizer_id };
        socket.emit('sendMessage', params);
    }

    useEffect(() => {
        if (!selectedRoom?.id) return;

        // Listen to the dynamic event
        socket.on(selectedRoom?.id, handleRoomMessage);

        return () => {
            socket.off(selectedRoom?.id);
        };
    }, [selectedRoom]);

    const handleRoomMessage = ({ status, data }) => {
        console.log(status, data);
        if (status) {
            setMessage('');
            setMessageList(prev => { return [...prev, data] });
        }
    }

    const openAttachmentModal = () => {
        getFolderList();
        setIsOpenAttachmentModal(true);
    }

    const getIcons = (file) => {
        const fileExtension = file.split('.').pop().toLowerCase() || null;

        switch (fileExtension) {
            case 'pdf':
                return <img src='/assets/icons/pdf-template.svg' alt='icon' className='size-7' />;
            case 'jpeg':
            case 'jpg':
            case 'png':
                return <img src='/assets/icons/img-template.svg' alt='icon' className='size-7' />;
            case 'doc':
            case 'docx':
                return <img src='/assets/icons/doc-template.svg' alt='icon' className='size-7' />;
            case 'csv':
                return <img src='/assets/icons/csv-template.svg' alt='icon' className='size-7' />;
            case 'xlsx':
                return <img src='/assets/icons/excel-template.svg' alt='icon' className='w-6 h-7' />;
            default:
                return <img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' />;
        }
    }

    const getFolderList = async () => {
        try {
            setIsFoldersLoading(true);
            const { data } = await GetFolderListApi();
            if (data?.status) {
                setData(data?.data);
                setFolders(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openFolder(item)}><img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' /><p>{item?.original_name}</p></div>),
                            addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                        };
                    })
                );
            }
            setIsFoldersLoading(false);
        } catch (error) {
            setIsFoldersLoading(false);
        }
    }

    useEffect(() => {
        if (!isOpenAttachmentModal) {
            setFolders(
                data?.map((item) => {
                    return {
                        ...item,
                        name: (<div className='flex items-center gap-3 cursor-pointer' onClick={() => openFolder(item)}><img src='/assets/icons/folder-red.svg' alt='icon' className='size-5' /><p>{item?.original_name}</p></div>),
                        addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                    };
                })
            );
            setBreadcrumbItems([]);
        }
    }, [isOpenAttachmentModal]);

    const openFolder = async (folder) => {
        try {
            setIsFolderListLoading(true);
            setCurrentFolder(folder);
            const { data } = await GetChildFolderListApi({ folder_id: folder?.id });
            if (data?.status) {
                setBreadcrumbItems(
                    data?.breadcrumb?.map((item) => {
                        if (item?.parentId) {
                            return {
                                title: (<p className='cursor-pointer' onClick={() => openFolder({ id: item?.id })} >{item?.name}</p>)
                            }
                        }
                        return null;
                    })?.filter(item => item !== null)
                );
                setFolders(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            name: (<div className='flex items-center gap-3 cursor-pointer' onClick={item?.type === 'folder' ? () => openFolder(item) : null} onDoubleClick={() => sendFile(item?.id, selectedRoom?.id, selectedRoom?.organizer_id)}>{getIcons(item?.original_name)}<p>{item?.original_name}</p></div>),
                            addedDate: item?.created_at ? moment(item?.created_at).format('lll') : '--',
                        };
                    })
                );
            }
            setIsFolderListLoading(false);
        } catch (error) {
            setIsFolderListLoading(false);
        }
    }

    const sendFile = (fileId, roomId, organizerId) => {
        if (!fileId || !roomId || !organizerId) { return; }
        setIsAttachmentLoading(true);

        let params = { token: token, chat_room_id: roomId, document_id: fileId, organizer_id: organizerId };
        socket.emit('sendMessage', params);

        setIsAttachmentLoading(false);
        setIsOpenAttachmentModal(false);
    }

    const createNewFolder = async (values) => {
        if (!currentFolder) {
            toast.error('Please select folder first to create new folder.');
            return;
        }
        try {
            setIsCreateFolderLoading(true);
            let params = {
                ...values,
                parent_id: currentFolder?.id
            }
            const { data } = await UploadFileFolderApi(params);
            if (data?.status) {
                toast.success(data?.message);
                openFolder(currentFolder);
                createFolderForm.resetFields();
                setIsCreateFolderModalOpen(false);
            }
            setIsCreateFolderLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsCreateFolderLoading(false);
        }
    }

    const uploadFileHandler = async (values) => {
        const extractOriginFileObjs = (fileArray) => {
            return fileArray.map(file => file.originFileObj);
        };
        try {
            if (!currentFolder) {
                toast.error('Please select folder first to upload.');
                return;
            }
            setIsUploadFileLoading(true);
            let params = {
                files: extractOriginFileObjs(values?.files),
                parent_id: currentFolder?.id
            }
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await UploadFileFolderApi(params, apiHeader);
            if (data?.status) {
                toast.success(data?.message);
                openFolder(currentFolder);
                uploadFileForm.resetFields();
                setIsOpenUploadModal(false);
            }
            setIsUploadFileLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsUploadFileLoading(false);
        }
    }

    useEffect(() => {
        setFileList([]);
        uploadFileForm.resetFields();
    }, [isOpenUploadModal]);

    useEffect(() => {
        createFolderForm.resetFields();
    }, [isCreateFolderModalOpen]);

    return (
        <div>
            <div>
                <div className='grid xl:grid-cols-4 md:grid-cols-2 gap-4'>
                    <div className='border-2 rounded-lg h-[calc(100vh-106px)] overflow-y-auto'>
                        <Tabs centered defaultActiveKey="1" onChange={handleTabsChange} tabBarStyle={{ width: '100%' }} className="custom-tabs" items={[{ key: '1', label: 'Active Chat', children: renderActiveChat() }, { key: '2', label: 'Archive', children: renderArchiveChat() }]} />
                    </div>
                    <div className='xl:col-span-3 border-2 rounded-lg py-3'>
                        {
                            selectedRoom ? (
                                <div>
                                    {isMessageListLoading ? (
                                        <div className='flex flex-col items-center gap-2 mt-40'>
                                            <Spin />
                                            <p className='text-secondaryLight1'>Loading Chat...</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className='h-[calc(100vh-186px)] overflow-y-auto px-3'>
                                                {
                                                    messageList?.map((item) => (
                                                        (
                                                            item?.message && <div key={item?.id}>
                                                                {
                                                                    userDetails?.id == item?.sender_id ? (
                                                                        /* Sent message */
                                                                        <div className='flex justify-end'>
                                                                            <div className='my-4 md:max-w-[80%]'>
                                                                                <div>
                                                                                    <div className='flex justify-between gap-5'>
                                                                                        <p className='text-secondaryLight1'>You</p>
                                                                                        <p className='text-secondaryLight1'>{moment(item?.created_at).format('lll')}</p>
                                                                                    </div>
                                                                                    <p className='bg-secondaryLight py-2 px-3 rounded-b-md rounded-tl-md font-medium'>{item?.message}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        /* Received message */
                                                                        <div className='flex items-start gap-3 my-4'>
                                                                            <div className='bg-secondaryLight p-2 rounded-md min-w-10 min-h-10 flex items-center justify-center'><img src='/assets/icons/chat-bot.svg' alt='icon' /></div>
                                                                            <div>
                                                                                <div className='flex justify-between mb-1'>
                                                                                    <p className='text-secondaryLight1'>{item?.users?.name}, {moment(item?.created_at).format('lll')}</p>
                                                                                </div>
                                                                                <p>{item?.message}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    ))
                                                }
                                                <div ref={messagesEndRef} />
                                            </div>
                                            {(!selectedRoom?.is_archive) &&
                                                <div className='px-3'>
                                                    <Input
                                                        className='mt-3' size='large'
                                                        placeholder='Send a message...'
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        onPressEnter={sendMessage}
                                                        prefix={
                                                            <div onClick={openAttachmentModal}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D32E2E" className="size-5 cursor-pointer">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                                                </svg>
                                                            </div>
                                                        }
                                                        suffix={
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#D32E2E" className="size-6 cursor-pointer" onClick={sendMessage}>
                                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clip-rule="evenodd" />
                                                            </svg>
                                                        }
                                                    />
                                                </div>}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='flex items-center justify-center h-full'>
                                    <div>
                                        <img src='/assets/images/strat-conversation.png' alt='image' className='mx-auto h-80' />
                                        <p className='text-center font-bold text-3xl mt-4'>Strat a new conversation</p>
                                        <p className='text-center text-secondaryLight2 mt-3 md:w-4/5 mx-auto'>Using this software, you can ask yourquestions and receive articles using artificial intelligence assistant</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* --- Attachment modal --- */}
            <ModalComponent isOpen={isOpenAttachmentModal} setIsOpen={setIsOpenAttachmentModal} width={800}>
                {isFoldersLoading ? (
                    <div className='flex flex-col items-center gap-2 my-28'>
                        <Spin />
                        <p className='text-secondaryLight1'>Loading...</p>
                    </div>
                ) : (
                    <div className={`${breadcrumbItems?.length > 0 ? 'table-radius-0' : ''}`}>
                        <div className='xl:col-span-3 border-2 rounded-lg relative'>
                            {
                                breadcrumbItems?.length > 0 &&
                                <Breadcrumb
                                    className='my-3 mx-4'
                                    separator={
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--text-color)" className="size-4 mt-1">
                                            <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                                        </svg>
                                    }
                                    items={breadcrumbItems}
                                />
                            }
                            <div className={`${breadcrumbItems?.length > 0 ? 'table-radius-0' : ''}`}>
                                <SkeletonTable columns={columns} data={folders} isLoading={isFolderListLoading} />
                            </div>
                        </div>
                        <div className='flex items-center justify-between'>
                            <div className='flex justify-end'>
                                {folders &&
                                    <p className='mt-5 text-secondaryLight1'>{isAttachmentLoading ? 'Sending a file...' : 'Double click on a file to send.'}</p>
                                }
                            </div>
                            <Dropdown menu={{ items: uploadItems }} trigger='click'>
                                <Button size='large' type='primary' className='mt-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                )}
            </ModalComponent>

            {/* --- Create new folder modal --- */}
            <ModalComponent isOpen={isCreateFolderModalOpen} setIsOpen={setIsCreateFolderModalOpen} title='Create new folder'>
                <Form className='w-full' autoComplete="off" form={createFolderForm} initialValues={folderFormValue} onFinish={createNewFolder}>
                    <div className='input-white'>
                        <label className='text-base text-[#2F2B3DCC] font-medium'>Folder Name</label>
                        <Form.Item name="name" className='mb-0' rules={[{ required: true, message: 'Please enter folder name.' }]}>
                            <Input placeholder="Folder name" className="mt-1 w-full ps-4 py-2" />
                        </Form.Item>
                    </div>
                    <LoadableButton
                        className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-6 rounded-lg themeHover duration-500'
                        type="submit"
                        lable='Save'
                        isLoading={isCreateFolderLoading}
                        loadingLable='Creating folder...'
                    />
                </Form>
            </ModalComponent>

            {/* --- UPload file modal --- */}
            <ModalComponent isOpen={isOpenUploadModal} setIsOpen={setIsOpenUploadModal} title='Upload a new file' width={600}>
                <Form className='w-full' autoComplete="off" form={uploadFileForm} initialValues={uploadFileFormValue} onFinish={uploadFileHandler}>
                    <div>
                        <Form.Item name="files" rules={[{ required: true, message: 'Please upload file.' }]}>
                            <Upload
                                beforeUpload={() => false}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 5 ? null : UploadButton}
                            </Upload>
                        </Form.Item>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                    <LoadableButton
                        className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-4 rounded-lg themeHover duration-500'
                        type="submit"
                        lable='Upload'
                        isLoading={isUploadFileLoading}
                        loadingLable='Uploading file...'
                    />
                </Form>
            </ModalComponent>
        </div >
    )
}
