import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../components/table/SkeletonTable.jsx';
import columns from '../../../columns/reviewDoc/viewReviewDoc.js';
import { Button, Form, Select, Spin } from 'antd';
import ModalComponent from '../../../components/modals/ModalComponent.jsx';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import LoadableButton from '../../../components/buttons/LoadableButton.jsx';
import { GetOrganizerAnswerApi, ReviewAnswerApi, GetInvoiceListApi, SendInvoiceApi, UnsubmitOrganizerApi } from '../../../api/request/taskManager/taskManager.js';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';

export default function ViewReviewDoc() {

    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({ invoice_temp_id: null });
    const [data, setData] = useState([]);
    const [invoiceOptions, setInvoiceOptions] = useState([]);
    const [isUnsubmitLoading, setIsUnsubmitLoading] = useState(false);
    const [isUnsubmitOpen, setIsUnsubmitOpen] = useState(false);

    const getAnswer = async () => {
        try {
            setIsLoading(true);
            let params = { organizer_id: id };
            const { data } = await GetOrganizerAnswerApi(params);
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        question: <div className={`${!item?.reviewed_checked ? 'font-semibold highlight-text' : ''}`}>{item?.field_label || '--'}</div>,
                        review: <div className='cursor-pointer' onClick={() => reviewHandler(item?.id, item?.reviewed_checked)}><img className='size-7' src={item?.reviewed_checked ? '/assets/icons/outlined-check-circle.svg' : '/assets/icons/outlined-circle.svg'} /></div>
                    };
                }));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAnswer();
    }, []);

    const sendInvoiceHandler = async (values) => {
        try {
            setIsLoadingSubmit(true);
            let params = { organizer_id: id, ...values };
            const { data } = await SendInvoiceApi(params);
            if (data?.status) {
                toast.success(data?.message);
                navigate('/review_doc');
            }
            form.resetFields();
            setIsModalOpen(false);
            setIsLoadingSubmit(false);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setIsLoadingSubmit(false);
        }
    }

    const spinner = <div>
        <div className="my-[3px]">
            <Spin
                indicator={
                    <LoadingOutlined bspin />
                }
            />
        </div>
    </div>

    const reviewHandler = async (id, status) => {
        try {
            setData(prev => {
                return prev.map(item => {
                    if (item.id == id) {
                        return { ...item, review: spinner };
                    } else {
                        return { ...item }
                    }
                })
            });
            let params = {
                id: id,
                reviewed_checked: status == 0 ? true : false
            };
            const { data } = await ReviewAnswerApi(params);
            if (data?.status) {
                setData(prev => {
                    return prev.map(item => item.id === id ?
                        {
                            ...item,
                            reviewed_checked: status == 0 ? 1 : 0,
                            question: <div className={`${status ? 'font-semibold highlight-text' : ''}`}>{item?.field_label || '--'}</div>,
                            review: <div className='cursor-pointer' onClick={() => reviewHandler(item?.id, status == 0 ? 1 : 0)}><img className='size-7' src={!status ? '/assets/icons/outlined-check-circle.svg' : '/assets/icons/outlined-circle.svg'} /></div>,
                        } : item)
                });
            }
        } catch (error) { }
    }

    useEffect(() => {
        if (!isModalOpen) {
            form.resetFields();
        }
    }, [isModalOpen]);

    const openInvoiceModal = async () => {
        try {
            setIsModalOpen(true);
            setIsLoadingInvoice(true);
            const { data } = await GetInvoiceListApi();
            if (data?.status) {
                setInvoiceOptions(
                    data?.data?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name
                        };
                    })
                );
            }
            setIsLoadingInvoice(false);
        } catch (error) {
            setIsLoadingInvoice(false);
        }
    }

    const unsubmitOrganizer = async () => {
        try {
            setIsUnsubmitLoading(true);
            const { data } = await UnsubmitOrganizerApi({ organizer_id: id });
            if (data?.status) {
                toast.success(data?.message);
                setIsUnsubmitOpen(false);
                navigate('/review_doc');
            }
            setIsUnsubmitLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setIsUnsubmitLoading(false);
        }
    }

    return (
        <div className='mb-6'>
            <div className='border-2 rounded-xl'>
                <div className='flex justify-between mx-5 gap-5 mt-4'>
                    <p className='font-semibold text-xl'>Organizer Name</p>
                    <Button type='primary' className='uppercase' onClick={() => setIsUnsubmitOpen(true)}>Unsubmit</Button>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>
            <Button type='primary' className='uppercase py-4 mt-4' onClick={openInvoiceModal}>Complete Review</Button>

            <ModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen} title='Invoice'>
                {
                    isLoadingInvoice ? (
                        <div className="flex justify-center flex-col my-[10%]">
                            <Spin size="large" />
                            <p className="primary text-center mt-2">Loading...</p>
                        </div>
                    ) : (
                        <Form autoComplete='off' form={form} initialValues={initialValues} onFinish={sendInvoiceHandler}>
                            <Form.Item name='invoice_temp_id' rules={[{ required: true, message: 'Please select invoice type!', },]}>
                                <Select placeholder='Select Invoice' options={invoiceOptions} size='large' />
                            </Form.Item>
                            <div className='flex justify-center '>
                                <LoadableButton
                                    className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-2'
                                    type='submit'
                                    lable='Send Invoice'
                                    loadingLable='Sending Invoice...'
                                    isLoading={isLoadingSubmit}
                                />
                            </div>
                        </Form>
                    )}
            </ModalComponent>

            {/* Unsubmit Organizer */}
            <ConfirmationModal
                isOpen={isUnsubmitOpen}
                setIsOpen={setIsUnsubmitOpen}
                message='Are you sure, want to unsubmit this organizer?'
                onConfirm={unsubmitOrganizer}
                isLoading={isUnsubmitLoading}
                loadingLabel='Loading...'
            />
        </div>
    )
}