import React from 'react';

export default function Communication({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1152 3.74025C22 5.07842 22 6.94127 22 10.667C22 14.3927 22 16.2555 21.1152 17.5937C20.7322 18.173 20.24 18.6704 19.6667 19.0575C18.51 19.8386 16.957 19.9373 14.125 19.9498V19.9516L12.6739 22.8844C12.1902 23.862 10.8098 23.862 10.3261 22.8844L8.875 19.9516V19.9498C6.04302 19.9373 4.49003 19.8386 3.33326 19.0575C2.76001 18.6704 2.26782 18.173 1.88478 17.5937C1 16.2555 1 14.3927 1 10.667C1 6.94127 1 5.07842 1.88478 3.74025C2.26782 3.16094 2.76001 2.66355 3.33326 2.27646C4.65743 1.38232 6.50079 1.38232 10.1875 1.38232H12.8125C16.4992 1.38232 18.3426 1.38232 19.6667 2.27646C20.24 2.66355 20.7322 3.16094 21.1152 3.74025Z" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5625 8.01416L15.4375 8.01416" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5625 13.3196H11.5" stroke={`${active ? primaryColor : 'currentColor'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}