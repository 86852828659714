import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function BarChart({ data, colors, height }) {

    if (!data || !Array.isArray(data.series) || !Array.isArray(data.categories)) {
        return null;
    }

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                barHeight: '50%',
                borderRadius: 12,
                borderRadiusApplication: 'end',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        xaxis: {
            categories: data?.categories,
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val, { seriesIndex, w }) {
                    return `${w.config.series[seriesIndex]?.prefix ? w.config.series[seriesIndex]?.prefix : ''} ${val} ${w.config.series[seriesIndex]?.postfix ? w.config.series[seriesIndex]?.postfix : ''}`;
                }
            },
            x: {
                formatter: function (val, { dataPointIndex }) {
                    if (Array.isArray(data?.symbols) && data.symbols[dataPointIndex]) {
                        return `${val} (${data.symbols[dataPointIndex]})`;
                    }
                    return val;
                }
            }
        },
        colors: colors,
        legend: {
            show: false
        }
    }
    
    return (
        <div>
            <ReactApexChart options={options} series={data?.series} type="bar" height={height || 325} />
        </div>
    )
}