import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../components/table/SkeletonTable.jsx';
import columns from '../../columns/prepareTax/prepareTax.js';
import Eye from '../../assets/Eye.jsx';
import { useNavigate } from 'react-router-dom';
import { GetTaskWiseOrganizerApi } from '../../api/request/taskManager/taskManager.js';
import moment from 'moment';

export default function PrepareTax() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const getOrganizers = async () => {
        try {
            setIsLoading(true);
            let params = {
                task_order: 'task2'
            };
            const { data } = await GetTaskWiseOrganizerApi(params);
            if (data?.status) {
                setData(data?.data?.map((item) => {
                    return {
                        ...item,
                        ...item?.user,
                        organizer: <div className='font-bold highlight-text capitalize'>{item?.organizer_name_slug}</div>,
                        submit: item?.is_submit ? 'Yes' : 'No',
                        startDate: item?.created_at ? (moment(item?.created_at).format('lll')) : '',
                        status: <p className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-600'} font-bold uppercase`}>{item?.status}</p>,
                        action: <div className='cursor-pointer' onClick={() => navigate(`/prepare_tax/${item?.id}`)}><Eye /></div>
                    };
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizers();
    }, []);

    return (
        <div>
            <div className='border-2 rounded-xl'>
                <div className='mt-4 mx-5'>
                    <p className=' font-semibold text-xl'>Organizers</p>
                </div>
                <div className='table-radius-0 text-light my-4'>
                    <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
                </div>
            </div>
        </div>
    )
}