import React, { useState } from 'react';
import MySegmented from '../../../components/segmented/Segmented.jsx';
import Client from './components/Client.jsx';
import Organizer from './components/Organizer.jsx';

export default function Tags() {

  const [segmentedValue, setSegmentedValue] = useState('Client');

  return (
    <div>
      <MySegmented options={['Client', 'Organizer']} value={segmentedValue} setValue={setSegmentedValue} />
      <div className='mt-4'>{segmentedValue === 'Client' ? (<Client />) : (<Organizer />)}</div>
    </div>
  )
}