import React, { useEffect, useState } from 'react';
import { GetReferralListApi } from '../../../api/request/clients/clients';
import columns from '../../../columns/clients/referrals.js';
import SkeletonTable from '../../../components/table/SkeletonTable.jsx';
import { Avatar } from 'antd';

export default function Referral({ id }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [referralCards, setReferralCards] = useState([
    { total: '0', title: 'My Referrals', icon: '/assets/icons/myRefferal.svg', bgColor: '#E9E7FD' },
    { total: '0', title: 'Registered', icon: '/assets/icons/registerRefferal.svg', bgColor: '#FBEAEA' },
    { total: '0', title: 'Paid Referrals', icon: '/assets/icons/paidRefferal.svg', bgColor: '#DDF6E8' },
    { total: '$ 0', title: 'Referrals Bonus', icon: '/assets/icons/bounsRefferal.svg', bgColor: '#E9E7FD' },
  ]);

  const getReferrals = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetReferralListApi({ user_id: id });
      if (data?.status) {
        const updatedTotals = [data?.data?.total_referral || 0, data?.data?.total_registered || 0, data?.data?.paid_referral || 0, `$ ${data?.data?.referral_bonus || 0}`];
        setReferralCards(referralCards.map((card, index) => ({ ...card, total: updatedTotals[index] })));
        setData(data?.data?.map((item) => {
          return {
            ...item,
            name: (<div className='flex gap-3 items-center'><Avatar src={item?.img} /> <p>{item?.name}</p></div>),
            status: <div className={`${item?.status ? 'text-green-500' : 'text-red-500'} font-semibold`}>{item?.status ? 'Active' : 'Inactive'}</div>,
          };
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div className='p-4'>
      <div className='grid md:grid-cols-2 xl:grid-cols-4 gap-5'>
        {referralCards.map((item, index) => (
          <div key={index} className='border-2 rounded-md flex items-center gap-5 p-5'>
            <div style={{ backgroundColor: item.bgColor }} className='rounded-md min-w-12 size-12 flex items-center justify-center'>
              <img src={item.icon} alt='icon' className='object-contain w-6 h-6' />
            </div>
            <div>
              <p className='text-base line-clamp-1'>{item.title}</p>
              <p className='font-bold text-2xl mt-1'>{item.total}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='table-radius-0 border-2 rounded-xl mt-5 pb-3'>
        <p className='p-4 font-semibold'>Referred Users</p>
        <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
      </div>
    </div>
  )
}