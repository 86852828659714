import React, { useEffect, useState } from 'react'
import SkeletonTable from '../../../../components/table/SkeletonTable'
import cellsColumn from '../../../../columns/settings/cells.js';
import ModalComponent from '../../../../components/modals/ModalComponent';
import { Form, Input, Select } from 'antd';
import LoadableButton from '../../../../components/buttons/LoadableButton';
import { AddUpdateCellInformationApi, CellInformationListApi, DeleteCellInformationApi, FormInformationListApi } from '../../../../api/request/settings/cellsAndForm.js';
import toast from 'react-hot-toast';
import Add from '../../../../assets/Add';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import Edit from '../../../../assets/Edit';
import Delete from '../../../../assets/Delete';

const Cells = () => {

  const [form] = Form.useForm();
  const initialCellValues = { field_name: '', form_id: null, cell_value: '' };
  const [isAddEditCellModalOpen, setIsAddEditCellModalOpen] = useState(false);
  const [isAddEditCellLoading, setIsAddEditCellLoading] = useState(false);
  const [isEditCell, setIsEditCell] = useState(false);
  const [isCellDelete, setIsCellDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [cellId, setCellId] = useState('');
  const [formInformationList, setFormInformationList] = useState([]);
  const [cellInformationList, setCellInformationList] = useState([]);

  const getCellInformationList = async () => {
    setIsLoading(true);
    try {
      const { data } = await CellInformationListApi();
      if (data.status) {
        setCellInformationList(data?.data?.map((item) => {
          return {
            ...item,
            action: <div className='flex items-center gap-3'>
              <button onClick={() => handleEditModal(item)}><Edit /></button>
              <button onClick={() => { setIsCellDelete(true); setCellId(item?.id) }}><Delete /></button>
            </div>
          }
        }));
      }
      setIsLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  }

  const getFormInformationList = async () => {
    try {
      const { data } = await FormInformationListApi();
      if (data?.status) {
        setFormInformationList(data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name
          };
        }));
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const handleEditModal = (cell) => {
    setIsAddEditCellModalOpen(true);
    setIsEditCell(true);
    setCellId(cell.id);
    form.setFieldsValue(cell);
  };

  const handleAddEditCell = async (values) => {
    try {
      setIsAddEditCellLoading(true);
      const payload = isEditCell ? { ...values, cell_id: cellId } : values;
      const { data } = await AddUpdateCellInformationApi(payload);
      if (data?.status) {
        setIsAddEditCellModalOpen(false);
        getCellInformationList();
        toast.success(data?.message);
      }
      setIsAddEditCellLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsAddEditCellLoading(false);
    }
  }

  const handleDeleteCell = async () => {
    setIsLoadingDelete(true);
    try {
      const { data } = await DeleteCellInformationApi({ cell_id: cellId });
      if (data?.status) {
        setIsCellDelete(false);
        getCellInformationList();
        toast.success(data?.message);
      }
      setIsLoadingDelete(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoadingDelete(false);
    }
  }

  useEffect(() => {
    getFormInformationList();
    getCellInformationList();
  }, []);

  useEffect(() => {
    if (!isAddEditCellModalOpen) {
      form.resetFields();
    }
  }, [isAddEditCellModalOpen])

  return (
    <div>
      <div className='border-2 rounded-xl my-5'>
        <div className='flex justify-between mx-5 mt-5'>
          <p className=' font-semibold text-2xl'>Cells</p>
          <div className='flex gap-3'>
            <button onClick={() => setIsAddEditCellModalOpen(true)} className='bg-primary text-white rounded-lg py-2 px-4 flex items-center gap-1'><Add />Add Cell</button>
          </div>
        </div>
        <div className='my-6'>
          <SkeletonTable columns={cellsColumn} data={cellInformationList} isLoading={isLoading} />
        </div>
      </div>

      {/* Add Cells */}
      <ModalComponent isOpen={isAddEditCellModalOpen} setIsOpen={setIsAddEditCellModalOpen} width={500} >
        <p className='mb-4 text-lg font-semibold text-center'>{!isEditCell ? 'Add Cell' : 'Edit Cell'}</p>
        <Form autoComplete='off' form={form} initialValues={initialCellValues} onFinish={handleAddEditCell}>
          <Form.Item name="field_name" className='mt-6' rules={[{ required: true, message: 'Please enter field name!' }]}>
            <Input placeholder='Enter feild name' size='large' />
          </Form.Item>
          <Form.Item name="cell_value" rules={[{ required: true, message: 'Please enter cell value!', },]} className='mt-6'>
            <Input placeholder='Enter cell value' size='large' />
          </Form.Item>
          <Form.Item name="form_id" rules={[{ required: true, message: 'Please select form', },]} className='mt-6'>
            <Select placeholder='Select Form' size="large" options={formInformationList} ></Select>
          </Form.Item>
          <div className='text-center'>
            <LoadableButton
              className='bg-primary text-white uppercase text-base font-semibold mt-2 py-2 rounded-lg px-4'
              type='submit'
              lable={!isEditCell ? 'Submit' : 'Update'}
              loadingLable={!isEditCell ? 'Adding cell...' : 'Updating cell...'}
              isLoading={isAddEditCellLoading}
            />
          </div>
        </Form>
      </ModalComponent >

      {/* Delete cell */}
      <ConfirmationModal
        isOpen={isCellDelete}
        setIsOpen={setIsCellDelete}
        message='Are you sure, want to delete this cell?'
        onConfirm={() => handleDeleteCell()}
        isLoading={isLoadingDelete}
        loadingLabel='Removing cell...'
      />
    </div>
  )
}

export default Cells