const defaultRender = (value) => (value ? value : '--');

const cellsColumn = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title: 'Created Date',
        dataIndex: 'created_at',
        render: defaultRender,
    },
    {
        title:'Action',
        dataIndex: 'action',
    },
];

export default cellsColumn;