const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Organizer Name',
        dataIndex: 'organizer_name_slug',
        render: defaultRender,
    },
    {
        title: 'Submitted',
        dataIndex: 'is_submit',
        render: defaultRender,
    },
    {
        title: 'Payment Status',
        dataIndex: 'is_payment',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title: 'Created Date',
        dataIndex: 'created_at',
        render: defaultRender,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: defaultRender,
    },
];

export default columns;