import { GetCellsDataApi } from "../api/request/organizer";

let cellsData = [];

const getData = async () => {
    try {
        const { data } = await GetCellsDataApi();
        if (data?.status) {
            cellsData = data?.data;
            return data?.data;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
}

export const getCellsData = async () => {
    return cellsData?.length ? cellsData : await getData();
}