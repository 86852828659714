import React, { useState } from 'react';
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom';
import { SendForgotPasswordLinkApi } from '../../api/request/auth.js';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import toast from 'react-hot-toast';

const Forgot = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ email: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            const { data } = await SendForgotPasswordLinkApi(values);
            if (data?.status) {
                toast.success(data?.message);
                form.resetFields();
                navigate('/login');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className='sm:min-h-screen flex justify-center items-center '>
                <Form autoComplete='off' className='sm:w-3/5 w-full' form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <div className='text-primary font-semibold text-lg flex items-center mb-5 cursor-pointer' onClick={() => navigate(-1)}><img src="assets/icons/Back.svg" alt="icon" />Back</div>
                    <p className='font-bold sm:text-4xl text-3xl text-secondary mb-5'>Forgot your password?</p>
                    <p className="text-lg text-secondaryLight2 mb-8">Don’t worry, happens to all of us. Enter your email below to recover your password</p>
                    <Form.Item type='email' className='mb-0 mt-5' name="email" rules={[
                        { required: true, message: 'Please enter your email!', },
                        { type: 'email', message: 'Please enter valid email!', },
                    ]}>
                        <Input placeholder='Email' size='large' />
                    </Form.Item>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        type='submit'
                        lable='Submit'
                        loadingLable='Submiting...'
                        isLoading={isLoading}
                    />
                </Form>
            </div>
        </div>
    )
}

export default Forgot