import React from 'react';

export default function FollowUp({ active }) {

    const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();

    return (
        <svg width="25" height="24" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4188 22.1085H2.47678C2.4151 22.1085 2.35595 22.084 2.31233 22.0404C2.26872 21.9968 2.24422 21.9376 2.24422 21.876V20.1887C2.24422 19.2236 2.93376 18.3724 3.99657 17.6491C5.89428 16.3538 8.96526 15.5375 12.4188 15.5375C12.6501 15.5375 12.8719 15.4456 13.0355 15.282C13.199 15.1185 13.2909 14.8967 13.2909 14.6654C13.2909 14.4341 13.199 14.2122 13.0355 14.0487C12.8719 13.8851 12.6501 13.7933 12.4188 13.7933C8.55595 13.7933 5.13613 14.7596 3.01283 16.2073C1.39304 17.3119 0.5 18.7154 0.5 20.1887V21.876C0.5 22.4002 0.708267 22.903 1.07898 23.2738C1.4497 23.6445 1.9525 23.8527 2.47678 23.8527H12.4188C12.6501 23.8527 12.8719 23.7609 13.0355 23.5973C13.199 23.4338 13.2909 23.2119 13.2909 22.9806C13.2909 22.7493 13.199 22.5275 13.0355 22.364C12.8719 22.2004 12.6501 22.1085 12.4188 22.1085ZM12.4188 0C8.88852 0 6.02335 2.86517 6.02335 6.39546C6.02335 9.92575 8.88852 12.7909 12.4188 12.7909C15.9491 12.7909 18.8143 9.92575 18.8143 6.39546C18.8143 2.86517 15.9491 0 12.4188 0ZM12.4188 1.74422C14.9863 1.74422 17.0701 3.82797 17.0701 6.39546C17.0701 8.96295 14.9863 11.0467 12.4188 11.0467C9.85132 11.0467 7.76757 8.96295 7.76757 6.39546C7.76757 3.82797 9.85132 1.74422 12.4188 1.74422Z" fill={`${active ? primaryColor : 'black'}`} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3958 12.791C16.0259 12.791 13.291 15.5259 13.291 18.8958C13.291 22.2656 16.0259 25.0005 19.3958 25.0005C22.7656 25.0005 25.5005 22.2656 25.5005 18.8958C25.5005 15.5259 22.7656 12.791 19.3958 12.791ZM19.3958 14.5352C21.8028 14.5352 23.7563 16.4888 23.7563 18.8958C23.7563 21.3028 21.8028 23.2563 19.3958 23.2563C16.9888 23.2563 15.0352 21.3028 15.0352 18.8958C15.0352 16.4888 16.9888 14.5352 19.3958 14.5352Z" fill={`${active ? primaryColor : 'black'}`} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5874 19.9156L18.3317 21.0784C18.4994 21.1901 18.7007 21.2402 18.9012 21.2203C19.1017 21.2004 19.2892 21.1116 19.4317 20.9691L22.3387 18.0621C22.5021 17.8986 22.594 17.6769 22.594 17.4458C22.594 17.2146 22.5021 16.9929 22.3387 16.8295C22.1752 16.666 21.9536 16.5742 21.7224 16.5742C21.4913 16.5742 21.2696 16.666 21.1061 16.8295L18.7038 19.2307L17.5549 18.4644C17.3624 18.3391 17.1283 18.2948 16.9034 18.3411C16.6785 18.3874 16.4809 18.5205 16.3535 18.7116C16.2261 18.9026 16.1792 19.1362 16.223 19.3616C16.2668 19.5871 16.3978 19.7861 16.5874 19.9156Z" fill={`${active ? primaryColor : 'black'}`} />
        </svg>
    )
}
