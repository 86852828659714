import React, { useEffect, useState } from 'react';
import { GetClientOrganizerApi } from '../../../api/request/clients/clients';
import { Spin } from 'antd';
import moment from 'moment';
import RadialBarChart from '../../../components/charts/RadialBarChart.jsx';

export default function Organizer({ id, tab }) {

  const [isLoading, setIsLoading] = useState(false);
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
  const [activeOrgType, setActiveOrgType] = useState(false);
  const [organizers, setOrganizers] = useState([]);

  const getOrganizer = async () => {
    if (!tab === '5') { return };
    try {
      setIsLoading(true);
      let params = {
        is_archive: activeOrgType,
        client_id: id
      }
      const { data } = await GetClientOrganizerApi(params);
      if (data?.status) {
        setOrganizers(data?.data)
      }
      setIsLoading(false);
    } catch (error) {
      setOrganizers([]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOrganizer();
  }, [tab, activeOrgType]);

  return (
    <div>
      <div className='bg-secondaryLight rounded-t-md flex justify-around uppercase font-semibold py-3'>
        <p className={`${activeOrgType ? '' : 'text-primary'} cursor-pointer duration-300`} onClick={() => setActiveOrgType(false)}>Active Organizer</p>
        <p className={`${activeOrgType ? 'text-primary' : ''} cursor-pointer duration-300`} onClick={() => setActiveOrgType(true)}>Archive</p>
      </div>

      <div>
        {isLoading ? (
          <div className="flex justify-center flex-col my-[10%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <div>
            {
              organizers?.length > 0 ? (
                <div className='grid md:grid-cols-2 gap-5 p-4'>
                  {
                    organizers?.map((item, index) => (
                      <div className='border-2 rounded-2xl py-4 ps-4'>
                        <p className='font-semibold text-lg capitalize'>{item?.organizer_type?.organizer_name}</p>
                        <div key={index} className='flex items-center justify-between cursor-pointer'>
                          <div>
                            <p className='font-medium mt-3'><span className='text-secondaryLight1'>Last Updated : </span>{moment(item?.updated_at).format('ll')}</p>
                            <div className='flex items-center gap-2 mt-3'>
                              <div className='size-3 rounded-full bg-primary'></div>
                              <p className='font-medium'>Seal</p>
                            </div>
                            <button className='rounded-full bg-primary text-white text-sm font-semibold uppercase py-1 px-6 mt-3'>Pending</button>
                          </div>
                          <div>
                            <RadialBarChart values={[45]} labels={['Completed']} colors={[primaryColor]} value={true} height={150} />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <p className='flex justify-center mt-40 text-secondaryLight1'>No organizer found</p>
              )
            }
          </div>
        )}
      </div>
    </div>
  )
}