import React, { useEffect, useState } from 'react';
import { GetOrganizerListApi } from '../../api/request/organizers/organizers.js';
import MySegmented from '../../components/segmented/Segmented.jsx';
import SkeletonTable from '../../components/table/SkeletonTable'
import column from '../../columns/organizers/organizers.js';
import moment from 'moment/moment';
import Eye from '../../assets/Eye.jsx';
import { useNavigate } from 'react-router-dom';

export default function Organizers() {

    const navigate = useNavigate();
    const [segmentedValue, setSegmentedValue] = useState('Review Doc');
    const [isLoading, setIsLoading] = useState(false);
    const [organizerList, setOrganizerList] = useState([]);

    const getFilterStatus = (type) => {
        switch (type) {
            case 'Review Doc':
                return 'task1';
            case 'Prepare Tax':
                return 'task2';
            case 'Review Tax':
                return 'task3';
            case 'Follow Up':
                return 'task4';
            case 'Return to Gov':
                return 'task5';
            default:
                return 'task1';
        }
    }

    const getRedirectionPath = (type) => {
        switch (type) {
            case 'Review Doc':
                return '/review_doc';
            case 'Prepare Tax':
                return '/prepare_tax';
            case 'Review Tax':
                return '';
            case 'Follow Up':
                return '';
            case 'Return to Gov':
                return '';
            default:
                return 'task1';
        }
    }

    const getOrganizerList = async () => {
        try {
            setIsLoading(true);
            let params = { task_order: getFilterStatus(segmentedValue) };
            const { data } = await GetOrganizerListApi(params);
            if (data?.status) {
                setOrganizerList(
                    data?.data?.map((item) => {
                        return {
                            ...item,
                            ...item?.user,
                            organizer: <div className='font-bold highlight-text capitalize'>{item?.organizer_name_slug}</div>,
                            submit: item?.is_submit ? 'Yes' : 'No',
                            startDate: item?.created_at ? (moment(item?.created_at).format('lll')) : '',
                            status: <p className={`${item?.status == 'active' ? 'text-green-500' : 'text-red-600'} font-bold uppercase`}>{item?.status}</p>,
                            action: <div className='cursor-pointer' onClick={() => navigate(`${getRedirectionPath(segmentedValue)}/${item?.id}`)}><Eye /></div>
                        };
                    })
                );
            }
            setIsLoading(false);
        } catch (error) {
            setOrganizerList([]);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizerList();
    }, [segmentedValue]);

    return (
        <div>
            <MySegmented options={['Review Doc', 'Prepare Tax', 'Review Tax', 'Follow Up', 'Return to Gov']} value={segmentedValue} setValue={setSegmentedValue} />

            <div className='border-2 rounded-xl my-5'>
                <div className='flex justify-between mx-5 mt-5'>
                    <p className=' font-semibold text-2xl'>Organizers</p>
                </div>
                <div className='my-6'>
                    <SkeletonTable columns={column} data={organizerList} isLoading={isLoading} />
                </div>
            </div>
        </div>
    )
}