import React, { useEffect, useState } from 'react';
import BarChart from '../../components/charts/BarChart.jsx';
import { Spin } from 'antd';
import AreaChart from '../../components/charts/AreaChart.jsx';
import { GetAdminDashboardApi } from '../../api/request/dashboard/dashboard.js';
import moment from 'moment';

export default function Dashboard() {

  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim()
  const secondary = (getComputedStyle(document.documentElement).getPropertyValue('--secondaryLight1'))?.trim();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [taskData, setTaskData] = useState([]);

  const [dashboardCards, setDashboardCards] = useState([
    { title: 'Active job by task', total: '00', icon: '/assets/icons/active-task.svg', bgColor: '#DDF6E8', color: '#40CD7F' },
    { title: 'Completed by employees', total: '00', icon: '/assets/icons/completed-by-employees.svg', bgColor: '#D7F5FC', color: '#03C3EC' },
    { title: 'Overdue tasks', total: '00', icon: '/assets/icons/overdue-tasks.svg', bgColor: '#E9E7FD', color: '#7367F0' },
    { title: 'Pending tickets', total: '00', icon: '/assets/icons/pending-tickets.svg', bgColor: '#FBEAEA', color: '#D32E2E' },
  ]);
  const totalClientData = { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], series: [{ name: 'Client', data: [12, 10, 28, 8, 19, 34, 49] }] };
  const signatureData = { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], series: [{ name: 'Client', data: [12, 10, 28, 16, 31, 21, 38] }] };

  const getDashboard = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetAdminDashboardApi();
      if (data?.status) {
        setData(data?.data);

        const updatedTotals = [data?.data?.active_job_by_task, data?.data?.completed_task_by_employee, data?.data?.over_due_task, data?.data?.pending_ticket];
        setDashboardCards(dashboardCards.map((card, index) => ({ ...card, total: updatedTotals[index] })));

        let completedTask = [];
        let pendingTask = [];
        let days = [];
        data?.data?.chart_data?.map((item) => {
          completedTask?.push(item?.completed_tasks);
          pendingTask?.push(item?.running_tasks);
          days?.push(`${moment(item?.date).format('MMM DD')}`);
        });
        setTaskData({ categories: days, series: [{ name: 'Completed', data: completedTask }, { name: 'Pending', data: pendingTask, prefix: '', postfix: '' }] })
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div className='mb-5'>
      {isLoading ? (
        <div className="flex justify-center flex-col mt-[20%]">
          <Spin size="large" />
          <p className="primary text-center mt-2">Loading...</p>
        </div>
      ) : (
        <div>
          <div className='grid sm:grid-cols-2 xl:grid-cols-4 gap-5'>
            {dashboardCards.map((item, index) => (
              <div key={index} className='border-2 rounded-xl ps-4 py-4'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center justify-between gap-5'>
                    <div className='rounded-xl size-14 flex items-center justify-center' style={{ backgroundColor: item?.bgColor }}>
                      <img src={item?.icon} alt='icon' />
                    </div>
                    <div>
                      <p className='font-medium line-clamp-1'>{item?.title}</p>
                      <p className='font-semibold text-2xl mt-1'>{item?.total}</p>
                    </div>
                  </div>
                  <div className='h-14 w-[5px] rounded-s-md' style={{ backgroundColor: item?.color }}></div>
                </div>
              </div>
            ))}
          </div>

          <div className='grid xl:grid-cols-3 gap-5 mt-5'>
            <div className='xl:col-span-2 border-2 rounded-xl'>
              <div className='flex justify-end mt-5 mx-5'>
                <div className='flex items-center gap-6'>
                  <div className='flex items-center gap-3'>
                    <div className='size-4 bg-secondaryLight1 rounded-full'></div>
                    <p className='text-secondaryLight1'>Completed</p>
                  </div>
                  <div className='flex items-center gap-3'>
                    <div className='size-4 bg-primary rounded-full'></div>
                    <p className='text-secondaryLight1'>Pending</p>
                  </div>
                </div>
              </div>
              <BarChart data={taskData} colors={[secondary, primaryColor]} height={285} />
            </div>
            <div className='grid sm:grid-cols-2 gap-5'>
              <div className='flex flex-col justify-between gap-5'>
                <div className='border-2 rounded-xl'>
                  <div className='p-4'>
                    <p className='font-medium line-clamp-1'>Total Client</p>
                    <p className='font-semibold text-2xl mt-1'>{data?.total_client || 0}</p>
                  </div>
                  <AreaChart data={totalClientData} colors={['#7367F0']} height={124} />
                </div>
                <div className='border-2 rounded-xl p-4'>
                  <div className='flex items-center gap-5'>
                    <div className='rounded-xl min-w-14 size-14 flex items-center justify-center bg-[#DDF6E8]'>
                      <img src='/assets/icons/invoice.svg' alt='icon' />
                    </div>
                    <div>
                      <p className='font-medium line-clamp-1'>Invoice</p>
                      <p className='font-semibold text-2xl mt-1'>{data?.invoice || 0}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-between gap-5'>
                <div className='border-2 rounded-xl p-4'>
                  <div className='flex items-center gap-5'>
                    <div className='rounded-xl min-w-14 size-14 flex items-center justify-center bg-[#FBEAEA]'>
                      <img src='/assets/icons/organizer.svg' alt='icon' />
                    </div>
                    <div>
                      <p className='font-medium line-clamp-1'>Organizer</p>
                      <p className='font-semibold text-2xl mt-1'>{data?.total_organization || 0}</p>
                    </div>
                  </div>
                </div>
                <div className='border-2 rounded-xl'>
                  <div className='p-4'>
                    <p className='font-medium line-clamp-1'>Signature</p>
                    <p className='font-semibold text-2xl mt-1'>{data?.signature || 0}</p>
                  </div>
                  <AreaChart data={signatureData} colors={['#03C3EC']} height={124} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
