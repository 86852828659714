const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Organizer',
        dataIndex: 'organizer',
        render: defaultRender,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Submit',
        dataIndex: 'submit',
        render: defaultRender,
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: defaultRender,
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title:'Action',
        dataIndex: 'action',
    },
];

export default columns;