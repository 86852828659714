import React from 'react';
const images = {
    Login: '/assets/images/login.png',
    ForgotPassword: '/assets/images/forgotPassword.png',
    ChangePassword: '/assets/images/changePassword.png',
    Otp: '/assets/images/otp.png',
}
const AuthLayout = ({ children, title }) => {
    const imageSrc = images[title];
    return (
        <>
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:p-0 p-5">
                <div className='h-full sm:mb-0 mb-5'>{children}</div>
                <div className='p-5'>
                    <div className='bg-primaryLight rounded-xl h-full flex flex-col justify-center items-center relative p-5'>
                        <p className='font-bold text-primary sm:text-4xl text-3xl text-left absolute top-2 left-3 p-4'>TaxTribe</p>
                        <img src={imageSrc} alt="image not found" className='sm:mt-0 mt-10' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthLayout