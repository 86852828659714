const defaultRender = (value) => (value ? value : '--');

const cellsColumn = [
    {
        title: 'Field Name',
        dataIndex: 'field_name',
        render: defaultRender,
    },
    {
        title: ' Cell Value',
        dataIndex: 'cell_value',
        render: defaultRender,
    },
    {
        title:'Action',
        dataIndex: 'action',
    },
];

export default cellsColumn;