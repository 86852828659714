const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: defaultRender,
    },
];

export default columns;